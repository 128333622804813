import { Link } from "react-router-dom";
import { SideWidget } from "./SideWidget";
import {CourseCard, coursesProps} from "./CourseCard";


export const AllCourses = (props:coursesProps) => {
 const courses= props?.courses?.map((course)=>{
    return CourseCard(course)
  })
  return (
      <>
        <div className="content-wrapper">
          <section className="section-frame overflow-hidden">
            <div className="wrapper bg-soft-primary">
              <div className="container py-12 py-md-16 text-center">
                <div className="row">
                  <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                    <h1 className="display-1 mb-3">
                      Intelligence Surveillance Academy  Courses
                    </h1>
                    <p className="lead px-lg-5 px-xxl-8 mb-1">
                      Here you can find the latest company courses and training
                      programs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="wrapper bg-light">
            <div className="container py-14 py-md-16">
              <div className="row gx-lg-8 gx-xl-12">
                <div className="col-lg-8">
                  <div className="blog classic-view">
                    <article className="post">
                      <div className="card">
                        <div className="card-body">
                          <div className="post-header">
                            <h2 className="post-title mt-1 mb-0">
                              Intelligence Surveillance Academy - COURSES{" "}
                            </h2>
                          </div>
                          <div className="post-content">
                            <p>
                              Intelligence Surveillance Academy company offers Link
                              series of covert surveillance courses at various
                              levels of complexity accredited by the ISO 9001 and ISO 27001 certifications. The courses are held both at
                              our headquarters, at your headquarters with
                              preliminary design or in any territorial training
                              base agreed by us and our clients.
                            </p>
                            <p>
                              The courses are mixed, of theory and practice,
                              progressively applied as the gradual learning. The
                              first hours are of theoretical training, followed
                              by classes of practical training with the
                              application in practice of the aquired knowledge.
                            </p>
                            <p>
                              Throughout the courses, the Intelligence Surveillance Academy provides all the necessary logistical
                              base, cars, communication means, hidden
                              documentation means and last but not least,
                              learning the surveillance activity by emphasizing
                              the preparation of the human factor. He is the
                              most important in supervisory actions.
                            </p>
                            <p>
                              The technique is auxiliary, being constantly
                              updated and modernized.
                            </p>
                            <p>
                              <em>
                                The question is: What is more important,
                                performing Link surveillance operation with
                                capturing all aspects or using sophisticated
                                equipment with the loss of operative moments?
                              </em>
                            </p>
                            <p>
                              In this activity the man drives the equipment, the
                              equipment does not do the work of the surveillance
                              officer.
                            </p>
                            <p>Our courses are as follows:</p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="blog grid grid-view">
                    <div className="row isotope gx-md-8 gy-8 mb-8">

                      {courses}
                    </div>
                  </div>
                </div>
                <SideWidget />
              </div>
            </div>
          </section>
        </div>
      </>
  );
};
