import { Link } from "react-router-dom";
import { SideWidget } from "../SideWidget";

export const CopyrightPolicy = () => {
  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        style={{ backgroundImage: 'url("../assets/img/photos/lglbg.webp")' }}
      >
        <div className="container pt-18 pb-15 pt-md-20 pb-md-19 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link
                    to="/terms-and-conditions"
                    className="text-reset"
                    rel="category"
                  >
                    Legal
                  </Link>
                </div>
                <h1 className="display-1 mb-4 text-white">Copyright Policy</h1>
                <ul className="post-meta text-white">
                  <li className="post-author">
                    <i className="uil uil-user" />
                    <Link to="#" className="text-reset">
                      <span>By ISA</span>
                    </Link>
                  </li>
                </ul>
                {/* /.post-meta */}
              </div>
              {/* /.post-header */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img src="../assets/img/photos/lglbg.webp" alt="" />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4">Copyright Policy </h2>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            This website and its content is copyright of{" "}
                            <strong>
                              Intelligence Surveillance Academy                             </strong>{" "}
                            All rights reserved.
                            <br /> Any redistribution or reproduction of part or
                            all of the contents in any form is prohibited other
                            than the following:
                            <ul>
                              <li>
                                • you may print or download to a local hard disk
                                extracts for your personal and non-commercial
                                use only
                              </li>
                              <li>
                                {" "}
                                • you may copy the content to individual third
                                parties for their personal use, but only if you
                                acknowledge the website as the source of the
                                material
                              </li>
                            </ul>
                          </p>

                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            You may not, except with our express written
                            permission, distribute or commercially exploit the
                            content. Nor may you transmit it or store it in any
                            other website or other form of electronic retrieval
                            system.
                          </p>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SideWidget />
          </div>
        </div>
      </section>
    </>
  );
};
