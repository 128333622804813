import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

const BasketContext = createContext();

export const useBasket = () => {
  return useContext(BasketContext);
};

export const BasketProvider = ({ children }) => {
  const [basket, setBasket] = useState(
    (Cookies.get("basket") &&
      JSON.parse(decodeURIComponent(Cookies.get("basket")))) ||
      []
  );

  useEffect(() => {
    Cookies.set("basket", JSON.stringify(basket));
  }, [basket]);

  const addToBasketProduct = (productId, quantity) => {
    const existingItemIndex = basket.findIndex(
      (item) => item.productId === productId
    );

    if (existingItemIndex !== -1) {
      if (productId < 1000) {
        const updatedBasket = [...basket];
        updatedBasket[existingItemIndex].quantity += quantity;
        setBasket(updatedBasket);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (basket?.some((bitem) => bitem.productId > 1000)) {
        window.location.href = "/isa-basket";
      }
    } else {
      setBasket([...basket, { productId, quantity }]);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const basketValue = {
    basket: basket,
    addToBasketProduct: addToBasketProduct,
    setBasket: setBasket,
  };

  return (
    <BasketContext.Provider value={basketValue}>
      {children}
    </BasketContext.Provider>
  );
};
