import { Link } from "react-router-dom";
import { SideWidget } from "../SideWidget";

export const Investigations = () => {
  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        style={{
          backgroundImage: 'url("../assets/img/photos/investigations.webp")',
        }}
      >
        <div className="container pt-18 pb-15 pt-md-20 pb-md-19 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link
                    to="/isa-services"
                    className="text-reset"
                    rel="category"
                  >
                    Services
                  </Link>
                </div>
                {/* /.post-category */}
                <h1 className="display-1 mb-4 text-white">
                  Service - Investigations
                </h1>
                <ul className="post-meta text-white">
                  <li className="post-author">
                    <i className="uil uil-user" />
                    <Link to="#" className="text-reset">
                      <span>By ISA</span>
                    </Link>
                  </li>
                </ul>
                {/* /.post-meta */}
              </div>
              {/* /.post-header */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img
                      src="../assets/img/photos/investigations.webp"
                      alt=""
                    />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4">Service - Investigations </h2>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            I have been conducting complex undercover
                            investigations for organized crime for 22 years and,
                            since 2012, with my personal company GD DIVISION
                            SRL, I have been conducting investigations mainly
                            for large companies around the world, private
                            clients from all the European countries, Israel,
                            USA. I would like to mention that nowadays
                            investigations are based on covert surveillance,
                            open source and legal source. Nobody wants to help
                            in any way to carry out investigations anymore. With
                            covert surveillance, after a well-thought-out plan,
                            several steps are taken to complete the
                            investigation. At the beginning and at the end of
                            the investigation, we will use the legally allowed
                            information sources allowed to put together all the
                            data and draw up the final report. FOR A FREE
                            CONSULTATION PLEASE CONTACT US
                          </p>
                          <br />
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SideWidget />
          </div>
        </div>
      </section>
    </>
  );
};
