import { Link } from "react-router-dom";

export const ContactUs = () => {
  return (
    <section className="wrapper bg-light">
      <div className="container pb-14 pb-md-16">
        <div className="row">
          <div className="col mt-n19 mb-16">
            <div className="card shadow-lg">
              <div className="row gx-0">
                <div
                  className="col-lg-6 image-wrapper bg-image bg-cover rounded-top rounded-lg-start d-none d-md-block"
                  style={{
                    backgroundImage: 'url("../assets/img/photos/tm3.webp")',
                  }}
                ></div>
                {/*/column */}
                <div className="col-lg-6">
                  <div className="p-10 p-md-11 p-lg-13">
                    <h2 className="display-4 mb-3">Let’s Talk</h2>
                    <p className="lead fs-lg">
                      Let's make something great together. We are trusted by
                      over 500+ clients. Join them by using our services and
                      grow your business.
                    </p>
                    <Link to="#" className="btn btn-primary rounded-pill mt-2">
                      Join Us
                    </Link>
                  </div>
                  {/*/div */}
                </div>
                {/*/column */}
              </div>
              {/*/.row */}
            </div>
            {/* /.card */}
          </div>
          {/* /column */}
        </div>
        {/* /.row */}
        <div className="row">
          <div className="col-xl-10 mx-auto">
            <div className="row gy-10 gx-lg-8 gx-xl-12">
              <div className="col-lg-8">
                <form
                  className="contact-form needs-validation"
                  method="post"
                  action="../assets/php/contact.php"
                  noValidate=""
                >
                  <div className="messages" />
                  <div className="row gx-4">
                    <div className="col-md-6">
                      <div className="form-floating mb-4">
                        <input
                          id="form_name"
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Jane"
                          required=""
                        />
                        <label htmlFor="form_name">First Name *</label>
                        <div className="valid-feedback"> Looks good! </div>
                        <div className="invalid-feedback">
                          {" "}
                          Please enter your first name.{" "}
                        </div>
                      </div>
                    </div>
                    {/* /column */}
                    <div className="col-md-6">
                      <div className="form-floating mb-4">
                        <input
                          id="form_lastname"
                          type="text"
                          name="surname"
                          className="form-control"
                          placeholder="Doe"
                          required=""
                        />
                        <label htmlFor="form_lastname">Last Name *</label>
                        <div className="valid-feedback"> Looks good! </div>
                        <div className="invalid-feedback">
                          {" "}
                          Please enter your last name.{" "}
                        </div>
                      </div>
                    </div>
                    {/* /column */}
                    <div className="col-md-6">
                      <div className="form-floating mb-4">
                        <input
                          id="form_email"
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="jane.doe@example.com"
                          required=""
                        />
                        <label htmlFor="form_email">Email *</label>
                        <div className="valid-feedback"> Looks good! </div>
                        <div className="invalid-feedback">
                          {" "}
                          Please provide a valid email address.{" "}
                        </div>
                      </div>
                    </div>
                    {/* /column */}
                    <div className="col-md-6">
                      <div className="form-select-wrapper mb-4">
                        <select
                          className="form-select"
                          id="form-select"
                          name="department"
                          required=""
                        >
                          <option selected="" disabled="" value="">
                            Select a department
                          </option>
                          <option value="Sales">Sales</option>
                          <option value="Marketing">Marketing</option>
                          <option value="Customer Support">
                            Customer Support
                          </option>
                        </select>
                        <div className="valid-feedback"> Looks good! </div>
                        <div className="invalid-feedback">
                          {" "}
                          Please select a department.{" "}
                        </div>
                      </div>
                    </div>
                    {/* /column */}
                    <div className="col-12">
                      <div className="form-floating mb-4">
                        <textarea
                          id="form_message"
                          name="message"
                          className="form-control"
                          placeholder="Your message"
                          style={{ height: 150 }}
                          required=""
                          defaultValue={""}
                        />
                        <label htmlFor="form_message">Message *</label>
                        <div className="valid-feedback"> Looks good! </div>
                        <div className="invalid-feedback">
                          {" "}
                          Please enter your messsage.{" "}
                        </div>
                      </div>
                    </div>
                    {/* /column */}
                    <div className="col-12 text-center">
                      <input
                        type="submit"
                        className="btn btn-primary rounded-pill btn-send mb-3"
                        defaultValue="Send message"
                      />
                      <p className="text-muted">
                        <strong>*</strong> These fields are required.
                      </p>
                    </div>
                    {/* /column */}
                  </div>
                  {/* /.row */}
                </form>
                {/* /form */}
              </div>
              {/*/column */}
              <div className="col-lg-4">
                <div className="d-flex flex-row">
                  <div>
                    <div className="icon text-primary fs-28 me-4 mt-n1">
                      {" "}
                      <i className="uil uil-location-pin-alt" />{" "}
                    </div>
                  </div>
                  <div>
                    <h5 className="mb-1">Address</h5>
                    <address>
                      146,R, Studio 210, London EC2A 3AR, United Kingdom
                    </address>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div>
                    <div className="icon text-primary fs-28 me-4 mt-n1">
                      {" "}
                      <i className="uil uil-phone-volume" />{" "}
                    </div>
                  </div>
                  <div>
                    <h5 className="mb-1">Phone</h5>
                    <p>
                      <Link to="tel:+40757047733">+40757047733</Link>
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div>
                    <div className="icon text-primary fs-28 me-4 mt-n1">
                      {" "}
                      <i className="uil uil-envelope" />{" "}
                    </div>
                  </div>
                  <div>
                    <h5 className="mb-1">E-mail</h5>
                    <p className="mb-0">
                      <Link
                        to="office@isasurveillancetraining.co.uk"
                        className="link-body"
                      >
                        office@isasurveillancetraining.co.uk
                      </Link>
                    </p>
                    {/*<p><Link to="mailto:help@sandbox.com" class="link-body">help@sandbox.com</Link></p>*/}
                  </div>
                </div>
              </div>
              {/*/column */}
            </div>
            {/*/.row */}
          </div>
          {/* /column */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
};
