import { Link } from "react-router-dom";
import { SideWidget } from "../SideWidget";

export const PrivacyPolicy = () => {
  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        style={{ backgroundImage: 'url("../assets/img/photos/lglbg.webp")' }}
      >
        <div className="container pt-18 pb-15 pt-md-20 pb-md-19 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link
                    to="/terms-and-conditions"
                    className="text-reset"
                    rel="category"
                  >
                    Legal
                  </Link>
                </div>
                <h1 className="display-1 mb-4 text-white">Privacy Policy</h1>
                <ul className="post-meta text-white">
                  <li className="post-author">
                    <i className="uil uil-user" />
                    <Link to="#" className="text-reset">
                      <span>By ISA</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img src="../assets/img/photos/lglbg.webp" alt="" />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4">Privacy Policy </h2>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            At Intelligence Surveillance Academy, your privacy is of
                            utmost importance to us. This Privacy Notice
                            outlines why privacy matters to us, what information
                            we collect, how we use it, and the rights you have
                            regarding your personal data. This notice applies to
                            all our services, including our website, software,
                            purchases, subscriptions, and any other interactions
                            with our company. We encourage you to carefully read
                            this policy and reach out to us with any questions
                            or concerns you may have about our privacy
                            practices.
                          </p>
                          <br />
                          <strong> Who We Are</strong>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            Data Controller: Intelligence Surveillance Academy 88
                            Church Road, Stockton-On-Tees, TS18 1TW ,
                            +40757047733 , office@isasurveillancetraining.co.uk
                          </p>
                          <br />
                          <strong> What Information Do We Collect</strong>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            We collect various types of personal information,
                            including:
                            <ul>
                              <li>
                                {" "}
                                • Contact Information: Such as names, addresses,
                                email addresses, and phone numbers.
                              </li>
                              <li>
                                {" "}
                                • Identification Information: Government-issued
                                ID, passport, or other identification documents.
                              </li>
                              <li>
                                {" "}
                                • Training and Certification Information: Data
                                related to training courses, certifications, and
                                professional qualifications.
                              </li>
                              <li>
                                {" "}
                                • Payment Information: Credit card details,
                                billing information, and payment history.
                              </li>
                              <li>
                                {" "}
                                • Usage Information: Details about how you use
                                our services, including website usage,
                                communication history, and interactions with our
                                platform.
                              </li>
                              <li>
                                {" "}
                                • Technical Information: IP addresses, browser
                                types, device types, and other technical data.
                              </li>
                            </ul>
                            We collect data through various methods, including
                            when you register, make purchases, use our services,
                            complete contact forms, or subscribe to newsletters.
                            In some cases, we may collect data from third
                            parties, and we will specify the categories of data
                            and their sources. We may also process sensitive
                            personal data or financial information, ensuring its
                            secure handling.
                          </p>
                          <br />
                          <strong> How Do We Use Personal Information</strong>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            We process personal data for a range of purposes,
                            including:
                            <ul>
                              <li>
                                {" "}
                                • Personalizing content and improving user
                                experiences.
                              </li>
                              <li> • Setting up and administering accounts.</li>
                              <li>
                                {" "}
                                • Delivering marketing and event communications.
                              </li>
                              <li> • Conducting polls and surveys.</li>
                              <li>
                                {" "}
                                • Performing internal research and development.
                              </li>
                              <li> • Providing goods and services.</li>
                              <li>
                                {" "}
                                • Fulfilling legal obligations, such as fraud
                                prevention.
                              </li>
                              <li> • Meeting internal audit requirements.</li>
                            </ul>
                            Please note that this list is not exhaustive, and we
                            only process data for lawful purposes.
                          </p>
                          <br />
                          <strong> Legal Basis for Processing</strong>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            We process your personal data based on various legal
                            grounds, including consent, contract performance,
                            legitimate interests, and legal obligations. We will
                            specify the legal basis for each processing activity
                            and provide information on how to withdraw consent
                            if applicable.
                          </p>
                          <br />
                          <strong> When Do We Share Personal Data</strong>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            We treat personal data confidentially and disclose
                            it only when necessary for the provision of services
                            or business operations. We will explain how we share
                            data, the safeguards in place, and the parties with
                            whom we may share data and why.
                          </p>
                          <br />
                          <strong>
                            {" "}
                            Where Do We Store and Process Personal Data
                          </strong>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            We will inform you if we intend to store and process
                            data outside your home country and outline the
                            measures, we have in place to protect your data and
                            ensure compliance with local laws. How Do We Secure
                            Personal Data We implement a range of measures to
                            protect personal data, including safeguards against
                            accidental loss, unauthorized access, and data
                            breaches. We continuously assess and improve our
                            data security measures.
                          </p>
                          <br />
                          <strong> Data Retention</strong>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            We retain personal data only for as long as
                            reasonably necessary for the purposes stated in this
                            notice. We have data retention schedules in place,
                            and we securely dispose of data when no longer
                            needed.
                          </p>
                          <br />
                          <strong>
                            {" "}
                            Your Rights in Relation to Personal Data
                          </strong>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            You have rights regarding your personal data,
                            including access, correction, deletion, withdrawal
                            of consent, data portability, restriction of
                            processing, objection, and the ability to lodge
                            complaints. We explain how to exercise these rights,
                            any relevant exemptions, and our identity
                            verification procedures.
                          </p>
                          <br />
                          <strong>
                            {" "}
                            Use of Automated Decision-Making and Profiling
                          </strong>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            We will disclose if we use profiling or automated
                            decision-making processes, explaining their
                            existence, logic, significance, and consequences.
                          </p>{" "}
                          <br />
                          <strong> How to Contact Us</strong> <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            If you have questions, concerns, or wish to file a
                            complaint about our privacy practices or your
                            personal data, please contact us through the
                            following means:
                            <ul>
                              <li>
                                {" "}
                                • Online:
                                https://geruinvestigation.co.uk/contact
                              </li>
                              <li>
                                {" "}
                                • Email: office@isasurveillancetraining.co.uk
                              </li>
                              <li>
                                {" "}
                                • Postal Mail: 88 Church Road, Stockton-On-Tees,
                                TS18 1TW
                              </li>
                            </ul>
                          </p>{" "}
                          <br />
                          <strong>
                            {" "}
                            Use of Cookies and Other Technologies
                          </strong>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            We use cookies and similar technologies for various
                            purposes, such as improving user experiences and
                            analyzing usage data. You can control and manage
                            these technologies; further information is available
                            https://geruinvestigation.co.uk/cookie-policy
                          </p>{" "}
                          <br />
                          <strong>
                            {" "}
                            Linking to Other Websites / Third-Party Content
                          </strong>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            Our website may link to external sites and
                            resources. We do not necessarily endorse these
                            sites, and we do not take responsibility for their
                            content or information. Please review the privacy
                            policies of these external sites when visiting them.
                          </p>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SideWidget />
          </div>
        </div>
      </section>
    </>
  );
};
