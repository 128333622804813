import { Link } from "react-router-dom";
import { SideWidget } from "../SideWidget";

export const InvestigationsCourse3 = () => {
  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        style={{ backgroundImage: 'url("../assets/img/photos/bg7.webp")' }}
      >
        <div className="container pt-18 pb-15 pt-md-20 pb-md-19 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link
                    to="/isa-investigations-courses"
                    className="text-reset"
                    rel="category"
                  >
                    Investigations Courses
                  </Link>
                </div>
                <h1 className="display-1 mb-4 text-white">
                  DEEPENING COURSE IN COVERT SURVEILLANCE - FOR FOR STUDENTS WITH BACKGROUND IN THE MILITARY, POLICE AND OTHER RELATED SPECIALIZATIONS
                </h1>
                <ul className="post-meta text-white">
                  <li className="post-author">
                    <i className="uil uil-user" />
                    <Link to="#" className="text-reset">
                      <span>By ISA</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img src="../assets/img/photos/b1.jpg" alt="" />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4">
                            CERTIFICATE IN COVERT SURVEILLANCE OPERAȚIONS - FOR
                            FOR STUDENTS WITH BACKGROUND IN THE MILITARY, POLICE
                            AND OTHER RELATED SPECIALIZATIONS
                          </h2>{" "}
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            The Level 4 Certificate in Covert Surveillance
                            Operations is a course designed to develop and
                            provide formal recognition of learners’ knowledge,
                            understanding and competence of surveillance
                            techniques. Surveillance is the monitoring of
                            thebehaviour, activities, or other changing
                            information, usually of people for the purpose of
                            intelligence gathering as part of an investigation
                            into unlawful or illegal activity, or for
                            enforcement activities.
                          </p>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            {" "}
                            The qualification is aimed at those who wish to gain
                            a recognised whether they are new to the
                            investigative or enforcement sectors or they have a
                            professional background in a related field and
                            intend to engage in continuing professional
                            development.
                          </p>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            The skills developed in the qualification will be
                            relevant to the private investigation sector and to
                            individuals with a military background carrying out
                            resettlement training prior to civilian life. All
                            learners will cover in Mandatory Group A the
                            principles of types surveillance, log keeping, third
                            party awareness, radio communication, managing
                            dynamic changes, planning and debriefing, legal
                            issues, the use of cameras and videos and mobile,
                            foot and static surveillance. In Mandatory Group B
                            learners will continue to cover further aspects of
                            mobile, foot and static surveillance, public
                            transport, health and safety risk ssessments and
                            development of leadership skills:
                          </p>
                          <br />
                          <ul>
                            <li>
                              {" "}
                              - Legal basis for carrying out surveillance.
                            </li>
                            <li> - Risk management</li>
                            <li>
                              {" "}
                              - Personality of the surveillance operative
                            </li>
                            <li> - Surveillance methods</li>
                            <li> - Documentation means.</li>
                            <li> - Camera. Description and use.</li>
                            <li> - Video camera. Covert types. How to use.</li>
                            <li> - Make-up and disguise.</li>
                            <li>
                              {" "}
                              - Documentation methods. Static method. Mobile
                              method.
                            </li>
                            <li> - Snapshot method.</li>
                            <li> - Learning documentation methods</li>
                            <li>
                              {" "}
                              - Covert communication mode. Applications used.
                              Communication radios. SIM card stations
                            </li>
                            <li> - Preparing an action.</li>
                            <li> - The surveillance operative bag</li>
                            <li> - Investigations prior to action.</li>
                            <li>
                              {" "}
                              - Study of the terrain where target surveillance
                              will be acquired.
                            </li>
                            <li>
                              {" "}
                              - Preparing the action plan. Establishing
                              positions.
                            </li>
                            <li> - Conducting the action briefing.</li>
                            <li>
                              {" "}
                              - Special notions concerning the organisation of a
                              surveillance operation.
                            </li>
                            <li> - Setting up the surveillance action</li>
                            <li>
                              {" "}
                              - Acquiring target surveillance. Acquisition
                              methods.
                            </li>
                            <li>
                              {" "}
                              - Acquisition by description - talking portrait.
                            </li>
                            <li> - Acquiring the target by photo</li>
                            <li> - Acquiring the target by direct hand-over</li>
                            <li>
                              {" "}
                              - Foot surveillance, methods according to the
                              environment applied.
                            </li>
                            <li> - Car surveillance in urban actions</li>
                            <li> - Car surveillance in rural actions</li>
                            <li> - Car surveillance on motorways</li>
                            <li>
                              {" "}
                              - Carrying out surveillance by public transport
                            </li>
                            <li> - Carrying out bus surveillance</li>
                            <li> - Carrying out surveillance by train</li>
                            <li> - Carrying out surveillance by metro</li>
                            <li> - Rural surveillance</li>
                            <li> - Operatives’ exposure</li>
                            <li>
                              {" "}
                              - Conducting countersurveillance and target
                              verification
                            </li>
                            <li> - Team leader&#39;s logbook</li>
                            <li>
                              {" "}
                              - Final report. Required documents. Preparation.
                              Requirements.
                            </li>
                            <li> - How to manage the surveillance actions.</li>
                          </ul>
                        </div>
                        {/* /.post-content */}
                      </article>
                      {/* /.post */}
                    </div>
                    {/* /.classic-view */}
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.blog */}
            </div>
            {/* /column */}
            <SideWidget />
            {/* /column .sidebar */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </>
  );
};
