import React, {useEffect} from "react";
import Axios from "axios";
import Cookies from "js-cookie";
import styled from "styled-components";
import {
  emailValidation,
  nameValidation,
  passwordConfirmationValidation,
  passwordValidation,
} from "../../utils/inputValidations";
import {StyledError} from "./Login";

const StyledTermAndConditionError = styled.p`
  color: white;
  background: rgba(255, 15, 15, 0.62);
  position: absolute;
  padding: 0 12px;
  bottom: -22px;
  left:0;
  z-index: -1;
  border-radius: 0 0 8px 8px;
  width: 100%;
  transition: all 0.4s ease;
  animation: errorAnimation 0.4s ease;
`
export const Register = () => {
  const [credentials, setCredentials] = React.useState({
    username: "",
    name: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    termAndConditions:false
  });
  React.useEffect(() => {
    if (Cookies.get('token')) {
      window.location.href = `/`;
    }
  }, []);
  const [errorState, setErrorState] = React.useState(false);
  const [alreadyExistedAccount, setAlreadyExistedAccount] = React.useState(false);
  const handleRegister = () => {
    setEmailError(emailValidation(credentials.email));
    setUsernameError(nameValidation(credentials.username));
    setNameError(nameValidation(credentials.name));
    setPasswordError(passwordValidation(credentials.password));
    setPasswordConfirmationError(
        passwordConfirmationValidation(credentials.passwordConfirmation, credentials.password),
    );
    if(!credentials.termAndConditions){
      setTermAndConditionError("Please accept Terms of service")
    }
    if (errorState) {
      return;
    }
    Axios.post("/api/signup", {
      username: credentials.username,
      name: credentials.name,
      email: credentials.email,
      password: credentials.password,
    })
      .then((response) => {
        window.location.href = `/login`;
      })
      .catch((error) => {
        if(error.response.data.message==='Already registered'){
          setAlreadyExistedAccount(true)
        }
      });
  };
  const [emailError, setEmailError] = React.useState("");
  const [termAndCondition, setTermAndCondition] = React.useState(false);
  const [usernameError, setUsernameError] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [termAndConditionError, setTermAndConditionError] = React.useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] =
    React.useState("");
  const onChangeHandle = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.target.id === "email") {
      setAlreadyExistedAccount(false)
      setEmailError(emailValidation(e.target.value));
      credentials.email = e.target.value;
    }
    if (e.target.id === "username") {
      setUsernameError(nameValidation(e.target.value));
      credentials.username = e.target.value;
    }
    if (e.target.id === "name") {
      setNameError(nameValidation(e.target.value));
      credentials.name = e.target.value;
    }
    if (e.target.id === "password") {
      setPasswordError(passwordValidation(e.target.value));
      setPasswordConfirmationError(
          passwordConfirmationValidation(e.target.value, credentials.passwordConfirmation),
      );
      credentials.password = e.target.value;
    }
    if (e.target.id === "password-confirmation") {
      setPasswordConfirmationError(
        passwordConfirmationValidation(e.target.value, credentials.password),
      );
      credentials.passwordConfirmation = e.target.value;
    }
  };
  React.useEffect(() => {
    if (
      emailError === "" &&
      passwordError === "" &&
      passwordConfirmationError === "" &&
        usernameError === "" &&
        nameError === "" && termAndConditionError === ""
    ) {
      if (credentials.email !== "" && credentials.password !== "" && credentials.username !== "" && credentials.name !== "" && credentials.passwordConfirmation !== "" && credentials.termAndConditions) {
        setErrorState(false);
      } else {
        setErrorState(true);
      }
    } else {
      setErrorState(true);
    }
  }, [emailError, passwordError,passwordConfirmationError,usernameError,nameError,termAndConditionError,termAndCondition]);

  return (
    <section className="my-3">
      <div className="container h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-lg-12 col-xl-11">
            <div
              className="card text-black"
              style={{ borderRadius: 25, backgroundColor: "#f1f5fd" }}
            >
              <div className="card-body p-md-5 p-2">
                <div className="row justify-content-center">
                  <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1 zindex-1">
                    <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                      Sign up
                    </p>
                    <div className="mx-1 mx-md-4">
                      <div className="d-flex flex-row align-items-center justify-content-center mb-4 position-relative">
                        <div className="form-outline flex-fill mb-0">
                          <label
                            className="form-label text-primary"
                            htmlFor="form3Example1c"
                          >
                            Your User Name
                          </label>
                          <input
                            type="username"
                            id="username"
                            name="username"
                            className="form-control text-primary"
                            onChange={onChangeHandle}
                          />
                          {usernameError && <StyledError>{usernameError}</StyledError>}
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-center mb-4 position-relative">
                        <div className="form-outline flex-fill mb-0">
                          <label
                            className="form-label text-primary"
                            htmlFor="form3Example1c"
                          >
                            Your Full Name
                          </label>
                          <input
                            type="name"
                            id="name"
                            name="name"
                            className="form-control text-primary"
                            onChange={onChangeHandle}
                          />
                          {nameError && <StyledError>{nameError}</StyledError>}
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-center mb-4 position-relative">
                        <div className="form-outline flex-fill mb-0">
                          <label
                            className="form-label text-primary"
                            htmlFor="form3Example3c"
                          >
                            Your Email
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control text-primary"
                            onChange={onChangeHandle}
                          />
                          {emailError && <StyledError>{emailError}</StyledError>}
                          {alreadyExistedAccount && <StyledError>This email is already registered.</StyledError>}
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-center mb-4 position-relative">
                        <div className="form-outline flex-fill mb-0">
                          <label
                            className="form-label text-primary"
                            htmlFor="form3Example4c"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            id="password"
                            name="password"
                            className="form-control text-primary"
                            onChange={onChangeHandle}
                          />
                          {passwordError && <StyledError>{passwordError}</StyledError>}
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-center mb-4 position-relative">
                        <div className="form-outline flex-fill mb-0">
                          <label
                            className="form-label text-primary"
                            htmlFor="form3Example4cd"
                          >
                            Repeat your password
                          </label>
                          <input
                            type="password"
                            id="password-confirmation"
                            className="form-control text-primary"
                            onChange={onChangeHandle}
                          />
                          {passwordConfirmationError && <StyledError>{passwordConfirmationError}</StyledError>}
                        </div>
                      </div>
                      <div className="form-check d-flex justify-content-start mb-5 position-relative">
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          defaultValue=""
                          id="form2Example3c"
                          onChange={(e) => {
                            credentials.termAndConditions=e.target.checked
                            setTermAndCondition(e.target.checked)
                            if(e.target.checked){
                              setTermAndConditionError("")
                            }
                          }}
                        />
                        <label
                          className="form-check-label text-primary"
                          htmlFor="form2Example3"
                        >
                          I agree all statements in{" "}
                          <a href="#">Terms of service</a>
                        </label>
                        {termAndConditionError && <StyledTermAndConditionError>{termAndConditionError}</StyledTermAndConditionError>}
                      </div>
                      <div className="d-flex flex-column align-items-center justify-content-center mx-4 mb-3 mb-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                          onClick={handleRegister}
                        >
                          Register
                        </button>
                        <p className="small fw-bold mt-2 pt-1 mb-0">
                          Already have an account?{" "}
                          <a href="/login" className="link-danger">
                            Login
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">
                    <img
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        borderRadius: "25px",
                      }}
                      src="../../assets/img/photos/tm3.jpg"
                      className="img-fluid"
                      alt="Sample image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
