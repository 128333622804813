import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import Axios from "axios";
import { useBasket } from "./Context/BasketContext";
import React, { useState } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const CheckoutPage = ({ books, onlineCourses }) => {
  const { basket, setBasket } = useBasket();
  if (basket.length === 0) {
    window.location.href = "/";
  }
  const [array, newArray] = useState(basket);
  const token = Cookies.get("token");
  const basketItems = array?.map((basketItem) => {
    if (basketItem.productId > 1000) {
      return {
        ...onlineCourses[basketItem.productId - 1001],
        quantity: basketItem.quantity,
      };
    } else
      return {
        ...books[basketItem.productId - 1],
        quantity: basketItem.quantity,
      };
  });
  let totalSum = 0;

  basketItems.forEach((basketItem) => {
    totalSum = totalSum + basketItem.price * basketItem.quantity;
  });

  const FUNDING_SOURCES = [FUNDING.PAYPAL, FUNDING.CARD];

  const initialOptions = {
    "client-id":
      "AYZVfrVqDhehFDuPc9N2oh50BRUBF1mCv7MpkzuT6lDh-_LPqEtErydhg1B-9x9L6ASNQuSDYdxVxJVS",
    "enable-funding": "paylater,venmo",
    currency: "GBP",
  };

  const onlineCoursesIDs = [];
  basketItems.map((item) => {
    if (item.id > 1000) {
      return onlineCoursesIDs.push(item.id);
    }
  });
  // const buyCourses = (onlineCoursesIDs) => {
  //   Axios.post("/api/buyCourses", {
  //     courses: onlineCoursesIDs,
  //     token: Cookies.get("token"),
  //   }).catch((error) => {
  //     console.error(error);
  //   });
  // };

  const checkoutItems =
    basketItems &&
    basketItems?.map((item) => {
      return (
        <li className="flex justify-between align-items-center">
          <div className="inline-flex align-items-center">
            <img
              style={{ objectFit: "contain", borderRadius: "10px" }}
              src={item.imgSrc}
              alt={item.title}
              className="max-h-16"
            />
            <div className="ml-3">
              <p className="text-base font-semibold text-white">{item.title}</p>
              <p className="text-sm font-medium text-white text-opacity-80">
                Quantity: {item.quantity}
              </p>
            </div>
          </div>
          <p className="text-sm font-semibold text-white break-keep min-w-fit">
            {(item.price * item.quantity).toFixed(2)} GBP
          </p>
        </li>
      );
    });

  let shippingCost = basketItems.some((item) => {
    return item.id < 1000;
  })
    ? 15
    : 0;

  const handleOrder = () => {
    if (token) {
      Axios.post("/api/process-purchase", {
        token: token,
        order: basketItems,
      }).catch(() => {
        toast("Oops! Something went wrong.", { type: "error" });
      });
    }
  };

  return (
    <div className="relative mx-auto w-full bg-white">
      <div className="grid grid-cols-10">
        <div className="col-span-full py-6 px-4 sm:py-12 lg:col-span-6 lg:py-24">
          <div className="mx-auto w-full max-w-lg">
            <h1 className="relative text-2xl font-medium text-gray-700 sm:text-3xl">
              Secure Checkout
              <span className="mt-2 block h-1 w-10 bg-[#001234] sm:w-20"></span>
            </h1>
            <form action="" className="mt-10 flex flex-col space-y-4">
              <PayPalScriptProvider options={initialOptions}>
                {FUNDING_SOURCES.map((fundingSource) => {
                  return (
                    <PayPalButtons
                      fundingSource={fundingSource}
                      key={fundingSource}
                      style={{
                        layout: "vertical",
                        shape: "rect",
                        color: fundingSource === FUNDING.PAYLATER ? "gold" : "",
                      }}
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                currency_code: "GBP",
                                value: (totalSum + shippingCost)
                                  .toFixed(2)
                                  .toString(),
                                breakdown: {
                                  item_total: {
                                    currency_code: "GBP",
                                    value: totalSum.toFixed(2).toString(),
                                  },
                                  shipping: {
                                    currency_code: "GBP",
                                    value: shippingCost,
                                  },
                                },
                              },
                              items: basketItems.map((product) => ({
                                name: product.title,
                                quantity: product.quantity,
                                unit_amount: {
                                  currency_code: product.currency,
                                  value: product.price,
                                },
                              })),
                            },
                          ],
                        });
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then(function (details) {
                          // onlineCoursesIDs.length > 0 &&
                          // buyCourses(onlineCoursesIDs);
                          handleOrder();
                          Cookies.remove("basket");
                          window.location.href = "/payment-successful";
                        });
                      }}
                    />
                  );
                })}
              </PayPalScriptProvider>
            </form>
            <p className="mt-10 text-center text-sm font-semibold text-gray-500">
              By placing this order you agree to the{" "}
              <a href={"/terms-and-conditions"} className={"hover:underline"}>
                {" "}
                Terms and Conditions
              </a>
            </p>
          </div>
        </div>
        <div className="relative col-span-full flex flex-col py-6 pl-8 pr-4 sm:py-12 lg:col-span-4 lg:py-24">
          <h2 className="sr-only">Order summary</h2>
          <div>
            <img
              src="../assets/img/photos/checkout-background.jpg"
              alt=""
              className="absolute inset-0 h-full w-full object-cover"
            />
            <div className="absolute inset-0 h-full w-full bg-gradient-to-t from-[#8aefe985] to-[#485c83] opacity-95"></div>
          </div>
          <div className="relative">
            <ul className="space-y-5">{checkoutItems}</ul>
            <div className="my-5 h-0.5 w-full bg-white bg-opacity-30"></div>
            <div className="space-y-2">
              {shippingCost > 0 && (
                <p className="flex justify-between text-lg font-bold text-white">
                  <span>Shipping cost: </span>
                  <span>{shippingCost} GBP</span>
                </p>
              )}
              <p className="flex justify-between text-lg font-bold text-white">
                <span>Total price :</span>
                <span>{(totalSum + shippingCost).toFixed(2)} GBP</span>
              </p>
            </div>
          </div>
          <div className="relative mt-10 text-white">
            <h3 className="mb-5 text-lg font-bold text-white">Support</h3>
            <p className="mt-1 text-sm font-semibold">
              office@isasurveillancetraining.co.uk
              <span className="font-light">(Email)</span>
            </p>
            <p className="mt-2 text-xs font-medium">
              Contact us now for payment related issues
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
