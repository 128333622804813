import { useEffect, useRef, useState } from "react";
import { Offcanvas } from "bootstrap";
import { Link } from "react-router-dom";
import { MiniBasket } from "../BasketComponent/MiniBasket";
import { useBasket } from "../Context/BasketContext";
import {useUser} from "../Context/UserContext";

export const HeaderContact = () => {
  const navbarRef = useRef(null);
  const { basket } = useBasket();
  const { loggedIn,logOut } = useUser();
  const [isIntersecting, setIsIntersecting] = useState(false);

  const navOffCanvasBtnRef = useRef(null);
  const navOffCanvasRef = useRef(null);
  let bsOffCanvas = null;
  useEffect(() => {
    const navOffCanvasBtn = document.querySelectorAll(".offcanvas-nav-btn");
    const navOffCanvas = document.querySelector(
      ".navbar:not(.navbar-clone) .offcanvas-nav",
    );
    bsOffCanvas = new Offcanvas(navOffCanvas, { scroll: true });
    const scrollLink = document.querySelectorAll(
      ".onepage .navbar li a.scroll",
    );
    const searchOffcanvas = document.getElementById("offcanvas-search");
    navOffCanvasBtn.forEach((e) => {
      e.addEventListener("click", (event) => {
        bsOffCanvas.show();
        const canvas = document.getElementById("offcanvas-container");
        if (canvas) {
          navOffCanvasRef.current.style.visibility = "visible";
        }
      });
    });
    scrollLink.forEach((e) => {
      e.addEventListener("click", (event) => {
        bsOffCanvas.hide();
      });
    });
    if (searchOffcanvas != null) {
      searchOffcanvas.addEventListener("shown.bs.offcanvas", function () {
        document.getElementById("search-form").focus();
      });
    }
    return () => {};
  }, [basket]);

  useEffect(() => {
    const handleScroll = () => {
      const positionScroll = window.pageYOffset;
      if (positionScroll >= 80) {
        setIsIntersecting(true);
      } else {
        setIsIntersecting(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (isIntersecting) {
      navbarRef.current.classList.add("transform_header");
    } else {
      navbarRef.current.classList.remove("transform_header");
    }
  }, [isIntersecting]);

  return (
    <>
      <header className="wrapper bg-soft-primary sticky_header general_header_sticky">
        <nav
          ref={navbarRef}
          className="navbar navbar-expand-lg center-nav transparent position-absolute navbar-dark py-1"
        >
          <div className="container flex-lg-row flex-nowrap align-items-center">
            <div className="navbar-brand w-100">
              <Link to="/">
                <img
                  style={{ width: "110px" }}
                  className="logo-dark"
                  src="../../assets/img/logo.webp"
                  srcSet="../../assets/img/logo@2x.webp 2x"
                  alt=""
                />
                <img
                  style={{ width: "110px" }}
                  className="logo-light"
                  src="../../assets/img/logo.webp"
                  srcSet="../../assets/img/logo-2x.webp 2x"
                  alt=""
                />
              </Link>
            </div>
            <div
              ref={navOffCanvasRef}
              id="offcanvas-container"
              className="navbar-collapse offcanvas offcanvas-nav offcanvas-start"
            >
              <div className="offcanvas-header d-lg-none">
                <h3 className="text-white fs-30 mb-0">Sandbox</h3>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                />
              </div>
              <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
                <ul className="navbar-nav align-items-center">
                  <li className="nav-item">
                    <Link className="nav-link" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/isa-about-us">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      data-bs-toggle="dropdown"
                    >
                      Training Courses
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link className="dropdown-item" to="/isa-all-training-courses">
                          All Training Courses
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="dropdown-item"
                          to="/isa-surveillance-courses"
                        >
                          Surveillance
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="dropdown-item"
                          to="/isa-investigations-courses"
                        >
                          Investigations
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="dropdown-item"
                          to="/isa-intelligence-courses"
                        >
                          Intelligence
                        </Link>
                      </li>
                    </ul>
                  </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/online-courses">
                            Online Courses
                        </Link>
                    </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/isa-books/all-books">
                      Books
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/isa-services">
                      Services
                    </Link>
                  </li>
                  {/*<li className="nav-item">*/}
                  {/*  <Link className="nav-link" to="/isa-faq">*/}
                  {/*    FAQ*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  <li className="nav-item">
                    <Link className="nav-link" to="/isa-blog">
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                        className="nav-link"
                        to="#"
                        data-bs-toggle="dropdown"
                    >
                      <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="#ffffff">
                        <path d="M12 10a3 3 0 100-6 3 3 0 000 6zm0 2a5 5 0 11.001-10.001A5 5 0 0112 12zm-7 8H3c0-4.418 4.468-8 9-8s9 3.582 9 8h-2c0-3.314-3.364-6-7-6s-7 2.686-7 6z"></path>
                      </svg>
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-start right-dropdown">
                      {loggedIn ? <>
                            <li className="nav-item">
                              <Link
                                  className="dropdown-item"
                                  to="/isa-online-courses"
                              >
                                Online Courses
                              </Link>
                            </li>
                            <li className="nav-item" onClick={()=>logOut()}>
                              <Link className="dropdown-item" to="/">
                                Log out
                              </Link>
                            </li>
                          </>
                          :
                          <>
                            <li className="nav-item">
                              <Link className="dropdown-item" to="/login">
                                Login
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                  className="dropdown-item"
                                  to="/register"
                              >
                                Register
                              </Link>
                            </li></> }
                    </ul>
                  </li>
                  <li className="nav-item">
                    <MiniBasket iconColor={"white"} />
                  </li>
                </ul>
                {/* /.navbar-nav */}
                <div className="offcanvas-footer d-lg-none">
                  <div className="w-full break-all ">
                    <Link
                      to="mailto:office@isasurveillancetraining.co.uk"
                      className="link-inverse"
                    >
                      office@isasurveillancetraining.co.uk
                    </Link>
                    <br /> <Link to="tel:+40757047733">+40757047733</Link>
                    <br />
                    <nav className="nav social social-white mt-4">
                      <Link to="#">
                        <i className="uil uil-twitter" />
                      </Link>
                      <Link to="#">
                        <i className="uil uil-facebook-f" />
                      </Link>
                      <Link to="#">
                        <i className="uil uil-dribbble" />
                      </Link>
                      <Link to="#">
                        <i className="uil uil-instagram" />
                      </Link>
                      <Link to="#">
                        <i className="uil uil-youtube" />
                      </Link>
                    </nav>
                    {/* /.social */}
                  </div>
                </div>
                {/* /.offcanvas-footer */}
              </div>
              {/* /.offcanvas-body */}
            </div>
            {/* /.navbar-collapse */}
          </div>
          {/* /.container */}
        </nav>
        {/* /.navbar */}
      </header>
      {/* /header */}
      <section className="wrapper bg-dark text-white">
        <div className="container pt-18 pt-md-20 pb-21 pb-md-21 text-center">
          <div className="row">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-6 col-xxl-5 mx-auto">
              <h1 className="display-1 text-white mb-3">Get in Touch</h1>
              <p className="lead px-xl-10 px-xxl-10">
                Have any questions? Reach out to us from our contact form and we
                will get back to you shortly.
              </p>
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </>
  );
};
