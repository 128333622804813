import { Link } from "react-router-dom";
import { SideWidget } from "../SideWidget";

export const IntelligenceCourse2 = () => {
  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        style={{ backgroundImage: 'url("../assets/img/photos/bg7.webp")' }}
      >
        <div className="container pt-18 pb-15 pt-md-20 pb-md-19 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link
                    to="/isa-intelligence-courses"
                    className="text-reset"
                    rel="category"
                  >
                    Intelligence Courses
                  </Link>
                </div>
                {/* /.post-category */}
                <h1 className="display-1 mb-4 text-white">
                  ESTHABLISHMENT, ORGANIZATION AND OPERATIONALIZATION PROACTIVE
                  SURVEILLANCE&amp; CLOSE PROTECTION DEPARTMENTS
                </h1>
                <ul className="post-meta text-white">
                  <li className="post-author">
                    <i className="uil uil-user" />
                    <Link to="#" className="text-reset">
                      <span>By ISA</span>
                    </Link>
                  </li>
                </ul>
                {/* /.post-meta */}
              </div>
              {/* /.post-header */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img src="../assets/img/photos/b1.jpg" alt="" />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4">
                            ESTHABLISHMENT, ORGANIZATION AND OPERATIONALIZATION
                            PROACTIVE SURVEILLANCE&amp; CLOSE PROTECTION
                            DEPARTMENTS
                          </h2>
                          <p>
                            We have reached a sensitive point of the close
                            protection activity, a high-demand activity in the
                            current society but which as a stand-alone activity
                            can no longer meet the new requirements that require
                            government and private customers, full security in
                            the conditions in which beating, violence never
                            manages to solve the problem.
                          </p>
                          <p>
                            All the attacks carried out succeeded in large part
                            due to the emphasis only on physical strength, the
                            impression displayed through clothing or clothing. I
                            bring to the attention that information is power,
                            information that unfortunately cannot be obtained by
                            sitting in a suit next to the person to whom we
                            ensure security. Personal security is the
                            simultaneous, integrated and inadvertent application
                            of the following knowledge and methods:
                          </p>
                          <ul>
                            <li>
                              Covert surveillance &amp; Proactive surveillance
                              Intelligenge
                            </li>
                            <li>
                              Close protection personal security no longer means
                              randomly firing a gun and beating, the conditions
                              no longer allow the conditions of such methods,
                              nor cars to observe from the plane, but conspired,
                              well-chosen, equipped cars that make identifying
                              the customer almost impossible
                            </li>
                            <li>
                              The teams are partially covered according to the
                              attributions, the only ones known from the field
                              being those from close protection, otherwise the
                              "ghosts" will act, but will not be seen to the
                              peace of mind of the client and the colleagues
                              from close protection
                            </li>
                            <li>
                              For this, after the organization and
                              operationalization of 2 units for the government
                              and 2 for private clients, we can organize and
                              operationalize such secret departments consisting
                              of civilian covert surveillance officers, civilian
                              intellgence and close protection that will
                              eliminate to the maximum the possibility of
                              executing such aggressions on customers
                            </li>
                            <li>
                              Preventing the event not showing up at the event
                              Stopping the event led by the client in full swing
                              Everything depends on when the preparation of the
                              aggression is identified: Before the arrival by
                              avoiding the event After arrival, before arriving
                              at the location During the event when the signs of
                              a possible aggression appear The use of these
                              methods seems to be prevention, but each team has
                              its role and reports simultaneously according to
                              its own attributions. One can overcome a safety
                              feature, but two or three elements are almost
                              impossible to overcome
                            </li>
                          </ul>
                          <p>
                            The interface with the public is only the close
                            protection officers, but the whole gear will never
                            be known, neither beautiful, nor weightlifters nor
                            arrogant, only citizens who work in the service of
                            the good.
                          </p>
                          <p>
                            We organize such departments through judicious
                            recruitments of officers specialized strictly in the
                            field in which they will work. The workers will be
                            retrained by types of duties, and the last stage
                            will be the union of the teams and their
                            harmonization in a concrete mechanism. Our work
                            involves many points to be achieved among which we
                            mention:
                          </p>
                          <ul>
                            <li>
                              The recruitment of officers/ assistance in the
                              recruitment of officers Theoretical training of
                              officers is carried out by separate trainers
                              specialized in special actions services, covert
                              surveillance, counterfilling and intelligence
                            </li>
                            <li>
                              Practice in the separate field under the
                              assistance of our trainers Bringing together
                              participants with separate specializations
                            </li>
                            <li>
                              Participation in joint actions under the
                              coordination of our trainers until the
                              operationalization of the department Recruitment,
                              training and homogenization of departments in 2
                              weeks, in case of departure of up to 25% of the
                              team members (resignations, illness, advancement)
                            </li>
                            <li>
                              Periodic training of the department at the request
                              of customers
                            </li>
                          </ul>
                        </div>
                        {/* /.post-content */}
                      </article>
                      {/* /.post */}
                    </div>
                    {/* /.classic-view */}
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.blog */}
            </div>
            {/* /column */}
            <SideWidget />
            {/* /column .sidebar */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </>
  );
};
