import React, { createContext, useContext, useState } from "react";
import Axios from "axios";
import Cookies from "js-cookie";

const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState(false);

    React.useEffect(()=>{
        const token = Cookies.get('token');
        if(token) {

            Axios.post("/api/takeUser", {
                token: token,
            })
                .then(() => {
                    setLoggedIn(true)
                })
                .catch((error) => {
                    setLoggedIn(false)
                    Cookies.remove('token')
                });

        }
    },[])

    const logOutFunction=()=>{
        setLoggedIn(false)
        Cookies.remove('token')
        Cookies.remove('basket')
        window.location.reload()
    }

    const userValues = {
        loggedIn: loggedIn,
        setLoggedIn: setLoggedIn,
        logOut:logOutFunction
    };

    return (
        <UserContext.Provider value={userValues}>
            {children}
        </UserContext.Provider>
    );
};
