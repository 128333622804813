import React from 'react';

const BlogPost = ({ image, title, link, content }) => {
    const shortContent = content.substring(0,220);
    return (
        <div className="bg-white p-4 rounded shadow-md mb-4">
            <img src={image} alt={title} className="mb-4 w-1/2" />
            <h2 className="text-2xl font-semibold">{title}</h2>
            <p className="text-gray-600 " dangerouslySetInnerHTML={{ __html: shortContent }} />
            <a href={link} target="_blank" className="text-blue-500 hover:underline mt-2 block">
                Read more
            </a>
        </div>
    );
};

export default BlogPost;