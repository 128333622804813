import React from "react";
import Cookies from "js-cookie";
import Axios from "axios";
import styled from "styled-components";
import {
  emailValidation,
  passwordValidation,
} from "../../utils/inputValidations";

export const StyledError= styled.p`
  color: white;
  background: rgba(255, 15, 15, 0.62);
  position: absolute;
  padding: 5px 12px 0;
  bottom: -20px;
  z-index: -1;
  border-radius: 0 0 8px 8px;
  width: 100%;
  transition: all 0.4s ease;
  animation: errorAnimation 0.4s ease;
`
export const StyledInvalidCredentialsError= styled.p`
  color: white;
  background: rgba(255, 15, 15, 0.62);
  position: absolute;
  padding: 0 12px;
  border-radius:10px;
  top:-20px;
  z-index: -1;
  width: 100%;
  transition: all 0.4s ease;
  opacity: 1;
  animation: errorAccountAnimation 0.4s ease;
`
export const Login = () => {
  const [credentials, setCredentials] = React.useState({
    email: "",
    password: "",
  });

  const [errorState, setErrorState] = React.useState(false);
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [invalidCredentials, setInvalidCredentials] = React.useState(false);
  const onChangeHandle = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.target.id === "email") {
      setEmailError(emailValidation(credentials.email));
      credentials.email = e.target.value;
    }

    if (e.target.id === "password") {
      setPasswordError(passwordValidation(credentials.password));
      credentials.password = e.target.value;
    }
  };


  React.useEffect(() => {
    if (emailError === "" && passwordError === "") {
      if (credentials.email !== "" && credentials.password !== "") {
        setErrorState(false);
      } else {
        setErrorState(true);
      }
    } else {
      setErrorState(true);
    }
  }, [credentials,emailError,passwordError]);

  React.useEffect(() => {
    if (Cookies.get('token')) {
      window.location.href = `/`;
    }
  }, []);
  const handleLogin = () => {
    Axios.post("/api/login", {
      email: credentials.email,
      password: credentials.password,
    })
        .then((response) => {
          Cookies.set("token", response.data.token,{expires:0.1});
          window.location.href = `/`;
        })
        .catch((error) => {
          setInvalidCredentials(true)
        });
  };

  return (
    <section className="">
      <div className="container-fluid h-custom container py-8 py-lg-16">
        <div className="row d-flex justify-content-center gap-6 align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5 w-fit">
            <img
              src="../../assets/img/logo-light.webp"
              className="img-fluid w-[200px] lg:w-[400px]"
              alt="Sample image"
            />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4">
            <div>
              {/* Email input */}
              <label
                className="form-label text-primary"
                htmlFor="form3Example3"
              >
                Email address
              </label>
              <div className="form-outline mb-5 position-relative">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control form-control-lg text-primary"
                  placeholder="Enter a valid email address"
                  onChange={onChangeHandle}
                />
                {emailError && <StyledError>{emailError}</StyledError>}
              </div>
              {/* Password input */}
              <div className="form-outline mb-5 position-relative">
                <label
                  className="form-label text-primary"
                  htmlFor="form3Example4"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control form-control-lg text-primary"
                  placeholder="Enter password"
                  onChange={onChangeHandle}
                />
                {passwordError && <StyledError>{passwordError}</StyledError>}
              </div>
              <div className="d-flex justify-content-between align-items-center">
                {/* Checkbox */}
                <div className="form-check mb-0">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    defaultValue=""
                    id="form2Example3"
                  />
                  <label className="form-check-label" htmlFor="form2Example3">
                    Remember me
                  </label>
                </div>
                <a href="/forgot-password" className="text-body">
                  Forgot password?
                </a>
              </div>
              <div className="text-center text-lg-start mt-4 pt-2 position-relative">
                {invalidCredentials && <StyledInvalidCredentialsError>Invalid credentials !!!</StyledInvalidCredentialsError>}
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  disabled={errorState}
                  style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                  onClick={handleLogin}
                >
                  Login
                </button>
                <p className="small fw-bold mt-2 pt-1 mb-0">
                  Don't have an account?{" "}
                  <a href="/register" className="link-danger">
                    Register
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between position-sticky top-100 py-4 px-4 px-xl-5 bg-primary">
        <div className="text-white mb-3 mb-md-0">
          Copyright © 2020. All rights reserved.
        </div>

        <div>
          <a href="#!" className="text-white me-4">
            <i className="fab fa-facebook-f" />
          </a>
          <a href="#!" className="text-white me-4">
            <i className="fab fa-twitter" />
          </a>
          <a href="#!" className="text-white me-4">
            <i className="fab fa-google" />
          </a>
          <a href="#!" className="text-white">
            <i className="fab fa-linkedin-in" />
          </a>
        </div>
      </div>
    </section>
  );
};
