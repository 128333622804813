import course from "../../assets/img/course.webp";
export const onlineCoursesData = [
  {
    id: 1001,
    title: "Lesson 1 - Definition of surveillance",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1001",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1002,
    title:
      "Lesson 2-Surveillance specialist operative required skills and appearance",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1002",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1003,
    title: "Lesson 3-Des code us to relate the actions environment",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1003",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1004,
    title: "Lesson 4-Type of objectivespursue by surveillance activities",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1004",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1005,
    title: "Lesson 5-Classification of surveillance actions",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1005",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1006,
    title: "Lesson 6-Preparing of surveillance actions",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1006",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1007,
    title: "Lesson 7-On site target investigations",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1007",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1008,
    title: "Lesson 8-Establish how surveillance commence",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1008",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1009,
    title: "Lesson 9-The spoken portrait method",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1009",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1010,
    title:
      "Lesson 10-Surveillance specific disquise and make-up in surveillance",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1010",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1011,
    title:
      "Lesson Lesson 11-Communications methods used in covert surveillance actions",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1011",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1012,
    title:
      "Lesson 12-Organizing a surveillance operations. Mandatory de-briefings",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1012",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },{
    id: 1013,
    title:
      "Lesson 13-Setting-up and organizing posts and waiting posts",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1013",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },{
    id: 1014,
    title:
      "Lesson 14-Equipment and technique required for surveillance operation",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1014",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },{
    id: 1015,
    title:
      "Lesson 15-Photographing and video recording in surveillance",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1015",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },{
    id: 1016,
    title:
      "Lesson 16-Performing surveillance actions",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1016",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },{
    id: 1017,
    title:
      "Lesson 17-Foot surveillance",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1017",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },{
    id: 1018,
    title:
      "Lesson 18-The Operational discussion about foot surveillance",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1018",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },{
    id: 1019,
    title:
      "Lesson 19-Surveillance performing by 1 investigator",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1019",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },{
    id: 1020,
    title:
      "Lesson 20-Surveillance performing by 2 investigators",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1020",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },{
    id: 1021,
    title:
      "Lesson 21-Placing contacts",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1020",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1022,
    title: "Lesson 22-Car selection in surveillance",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1022",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1023,
    title: "Lesson 23-Disadvantages and advantages of using GPRS in surveillance",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1023",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1024,
    title: "Lesson 24-Car surveillance methods when the target moving by car",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1024",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1025,
    title: "Lesson 25-Car surveillance on localities on congested and semi-congested streets",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1025",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1026,
    title: "Lesson 26-Natural ways of changing plans on car surveillance",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1026",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1027,
    title: "Lesson 27-Car surveillance at night",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1027",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1028,
    title: "Lesson 28-Static surveillance",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1028",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1029,
    title: "Lesson 29-Static surveillance",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1029",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1030,
    title: "Lesson 30-Drafting the action report",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1030",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1031,
    title: "Lesson 31-Motorbike as part of the surveillance team",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1031",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1032,
    title: "Lesson 32-Rules in car surveillance. Performing positioned posts and change of plans in mobile surveillance",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1032",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  },
  {
    id: 1033,
    title: "Lesson 33-Car surveillance methods when the target moving at roundabout",
    trailerVideoSrc: "",
    courseVideoSrc: "",
    content: "",
    link: "/isa-online-courses/1033",
    price: 24.99,
    currency: "GBP",
    imgSrc: course,
  }
];
