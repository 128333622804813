import React from 'react';
import BlogPost from './BlogPost';

const Blog = ({ posts }) => {
    return (
        <>
            {posts.map((post, index) => (
                <BlogPost
                    key={index}
                    image={post.image}
                    title={post.title}
                    link={post.link}
                    content={post.content}
                />
            )).reverse()}
        </>
    );
};

export default Blog;