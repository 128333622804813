import { Link } from "react-router-dom";
import { useBasket } from "../Context/BasketContext";
import { useEffect } from "react";

export const MiniBasket = ({ iconColor }) => {
  const { basket } = useBasket();
  const totalQuantity = basket.reduce(
    (total, item) => total + item.quantity,
    0,
  );

  return (
    <Link to="/isa-basket">
      <button
        type="button"
        className="py-3 pr-4 inline-flex justify-center items-center gap-2 border-gray-900 font-semibold text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 transition-all text-sm dark:hover:bg-gray-100 dark:border-gray-100 dark:hover:border-gray-900 dark:text-white dark:focus:ring-gray-900 dark:focus:ring-offset-gray-800"
      >
        <div className="relative">
          <div className="bottom-3 absolute left-3">
            <p className="flex h-2 w-2 items-center justify-center rounded-full bg-red-500 p-3 text-xs text-white">
              {totalQuantity}
            </p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.7"
            stroke={iconColor}
            className="file: h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            />
          </svg>
        </div>
      </button>
    </Link>
  );
};
