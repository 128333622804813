import { Link, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Axios from "axios";
import Cookies from "js-cookie";

import { SideWidget } from "../SideWidget";
import VideoPlayer from "./VideoPlayer";
import { useBasket } from "../Context/BasketContext";
import { useUser } from "../Context/UserContext";

export const OnlineCourse = ({ onlineCourses }) => {
  const { addToBasketProduct } = useBasket();
  const { id } = useParams();
  const { loggedIn } = useUser();
  const [hasThisBought, setHasThisBought] = useState(false);
  const [error, setError] = useState(false);
  const [sesh, setSesh] = useState("");

  const [loading, setLoading] = useState(!!Cookies.get("token"));
  const onlineCourse = onlineCourses[id - 1001];

  if (!onlineCourse) {
    window.location.href = "/isa-online-courses";
  }
  const userToken = Cookies.get("token");

  useEffect(() => {
    if (userToken) {
      setLoading(true);
      Axios.get(`/api/get-user-permissions`, {
        params: {
          token: userToken,
        },
      })
        .then((res) => {
          setSesh(res.data.message);
          setHasThisBought(true);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setError(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const video = document.getElementById("video");

  if (video) {
    const handleAttributeChange = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "controlslist"
        ) {
          const controlsListValue = video.getAttribute("controlslist");
          if (controlsListValue !== "nodownload") {
            video.setAttribute("controlslist", "nodownload");
          }
        }
      });
    };

    const observer = new MutationObserver(handleAttributeChange);

    observer.observe(video, {
      attributes: true,
      attributeFilter: ["controlslist"],
    });
  }

  return (
    <div>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        style={{ backgroundImage: 'url("../assets/img/photos/bg7.webp")' }}
      >
        <div className="container pt-18 pb-15 pt-md-20 pb-md-19 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link
                    to="/isa-online-courses"
                    className="text-reset"
                    rel="category"
                  >
                    All Online Courses
                  </Link>
                </div>
                <h1 className="display-1 mb-4 text-white">Course</h1>
                <ul className="post-meta text-white">
                  <li className="post-author">
                    <i className="uil uil-user" />
                    <Link to="#" className="text-reset">
                      <span>By ISA</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container py-14 py-md-16">
        <div className="row gx-lg-8 gx-xl-12">
          <div className="col-lg-8">
            <div>
              {!error && (
                <div className="card">
                  {loading ? (
                    <span
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <i className="c-inline-spinner"></i>
                    </span>
                  ) : hasThisBought ? (
                    <figure className="flex flex-row justify-around card-img-top overlay overlay-1 hover-scale w-100">
                      <video
                        id="video"
                        controls
                        controlsList="nodownload"
                        disablePictureInPicture
                      >
                        <source
                          src={`/api/video/${id}.mp4?token=${Cookies.get(
                            "token"
                          )}`}
                          type="video/mp4"
                        />
                      </video>
                    </figure>
                  ) : (
                    <div className={"w-100 h-fit position-relative"}>
                      <div
                        className={
                          "w-100 h-100 p-6 position-absolute top-0 bg-black bg-opacity-50 d-flex flex-column align-items-center justify-content-center"
                        }
                      >
                        <svg
                          fill="#FFFFFF"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 50 50"
                          width="250px"
                          height="250px"
                        >
                          <path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.300781 20 6 21.300781 6 23 L 6 47 C 6 48.699219 7.300781 50 9 50 L 41 50 C 42.699219 50 44 48.699219 44 47 L 44 23 C 44 21.300781 42.699219 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 25 30 C 26.699219 30 28 31.300781 28 33 C 28 33.898438 27.601563 34.6875 27 35.1875 L 27 38 C 27 39.101563 26.101563 40 25 40 C 23.898438 40 23 39.101563 23 38 L 23 35.1875 C 22.398438 34.6875 22 33.898438 22 33 C 22 31.300781 23.300781 30 25 30 Z" />
                        </svg>
                        <h2
                          className={
                            "text-white text-center text-lg md:text-3xl"
                          }
                        >
                          Purchase premium access to watch this course!
                        </h2>
                      </div>
                      <img src={onlineCourse.imgSrc} alt="course_thumbnail" />
                    </div>
                  )}
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        {!hasThisBought && (
                          <>
                            {!loggedIn && (
                              <p className={"w-100 text-center"}>
                                You must be logged in to buy this course.(
                                <a
                                  className={"text-primary underline"}
                                  href={"/login"}
                                >
                                  Log in
                                </a>
                                )
                              </p>
                            )}
                            <div className="flex items-center justify-between my-4 flex-col  sm:flex-row">
                              {!loading && (
                                <p>
                                  <span className="text-3xl font-bold text-slate-900">
                                    {onlineCourse.price} {onlineCourse.currency}
                                  </span>
                                </p>
                              )}
                              {!loading && (
                                <div className={"flex flex-column"}>
                                  <button
                                    onClick={() =>
                                      addToBasketProduct(onlineCourse.id, 1)
                                    }
                                    disabled={!loggedIn}
                                    className="flex items-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:bg-gray-300"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="mr-2 h-6 w-6"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                                      />
                                    </svg>
                                    Unlock all courses
                                  </button>
                                </div>
                              )}
                            </div>
                            <hr />
                          </>
                        )}
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4 mt-2">{onlineCourse.title}</h2>
                          <p
                            style={{ whiteSpace: "pre-line" }}
                            dangerouslySetInnerHTML={{
                              __html: onlineCourse.content,
                            }}
                          />
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <SideWidget />
        </div>
      </div>
    </div>
  );
};
