import { Link } from "react-router-dom";
import { SideWidget } from "../SideWidget";

export const IntelligenceCourse1 = () => {
  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        style={{ backgroundImage: 'url("../assets/img/photos/bg7.webp")' }}
      >
        <div className="container pt-18 pb-15 pt-md-20 pb-md-19 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link
                    to="/isa-intelligence-courses"
                    className="text-reset"
                    rel="category"
                  >
                    Intelligence Courses
                  </Link>
                </div>
                {/* /.post-category */}
                <h1 className="display-1 mb-4 text-white">
                  ESTHABLISHMENT, ORGANIZATION AND OPERATIONALIZATION OF COVERT
                  SURVEILLANCE DEPARTMENTS
                </h1>
                <ul className="post-meta text-white">
                  <li className="post-author">
                    <i className="uil uil-user" />
                    <Link to="#" className="text-reset">
                      <span>By ISA</span>
                    </Link>
                  </li>
                </ul>
                {/* /.post-meta */}
              </div>
              {/* /.post-header */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img src="../assets/img/photos/b1.jpg" alt="" />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4">
                            ESTHABLISHMENT, ORGANIZATION AND OPERATIONALIZATION
                            OF COVERT SURVEILLANCE DEPARTMENTS
                          </h2>
                          <p>
                            At the request of government organizations,
                            companies, businessmen, we set up investigative
                            surveillance departments, investigation agencies to
                            serve the company from an informational and
                            investigative point of view and to cover the entire
                            range of requirements:
                          </p>
                          <ul>
                            <li>Protection of companies' information</li>
                            <li>
                              Verification of top candidates for key positions,
                              with decision-making power at the top of the
                              company
                            </li>
                            <li>Verification of employees' honesty</li>
                            <li>Checking the honesty of employees</li>
                            <li>
                              Accompanying the management at events to ensure a
                              proactve surveillance
                            </li>
                            <li>
                              Verification of businessmen with whom the company
                              intends to develop partnerships
                            </li>
                            <li>
                              Verification of subordinated employees on how they
                              carry out their activity Ensuring the
                              investigative activity of financial and financial
                              companies assurances
                            </li>
                            <li>
                              Any other problems whose resolution leads to the
                              use of covert surveillance activity is indicated
                            </li>
                          </ul>
                          <p>
                            One thing we have to say about these departments.
                            They usually subordinate themselves exclusively to
                            the head of the company. The boss-subordinate
                            relationship exists only between the CEO and the
                            Surveillance Manager.
                          </p>
                          <p>
                            The CEO will be the only one who will know the
                            surveillance manager and the connection between them
                            will be through online, telephone paths.
                          </p>
                          <p>
                            Organization of departments / structures / teams /
                            covert surveillance, intelligence for companies,
                            corporations, enforcement organizations lasts for 3
                            months and involves carrying out the following
                            activities:
                          </p>
                          <ul>
                            <li>
                              Recruiting officers/assisting in the recruitment
                              of officers{" "}
                            </li>
                            <li>
                              Theoretical training of officers in the line of
                              investigations and covert investigations{" "}
                            </li>
                            <li>
                              Practice in the field under the assistance of our
                              trainers{" "}
                            </li>
                            <li>
                              Participation in actions under the coordination of
                              our trainers (accredited detectives) until the
                              operationalization of the department and achieving
                              the target of working alone team{" "}
                            </li>
                            <li>
                              Teaching the department *turnkey* and providing
                              consultancy after operationalization
                            </li>
                            <li>
                              Activities of recruitment, training and
                              homogenization of the departments in 2 weeks, in
                              case of departure of up to 40% of the team members
                            </li>
                            <li>
                              Periodic training at the request of the
                              beneficiaries
                            </li>
                            <li>Providing advice in more difficult cases</li>
                          </ul>
                          <p>
                            After completing the activity of organizing a
                            department of this kind, we ensure the delivery of a
                            functional department that meets all the
                            requirements of a professional structure of this
                            kind, the activity can be started immediately.
                          </p>
                          <p>
                            Complete training of team members at a higher level
                            Homogenization of team members Establishing the
                            department manager Establishment and consultancy in
                            the purchase of necessary equipment Establishing the
                            roles of team members for the efficiency of
                            activities according to their skills Consultancy in
                            choosing the conspired headquarters, the mandatory
                            elements to be fulfilled.
                          </p>
                        </div>
                        {/* /.post-content */}
                      </article>
                      {/* /.post */}
                    </div>
                    {/* /.classic-view */}
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.blog */}
            </div>
            {/* /column */}
            <SideWidget />
            {/* /column .sidebar */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </>
  );
};
