import { Link } from "react-router-dom";
import { Post } from "./Post";
import { SideWidget } from "../SideWidget";

export const AllBooks = ({ books }) => {
  const allBooksArticle =
    books &&
    books.map((article, index) => {
      return (
        <Post
          key={index}
          link={article.link}
          imgSrc={article.imgSrc}
          title={article.title}
          content={article.content.slice(0, 200) + "..."}
        />
      );
    });

  return (
    <>
      <div className="content-wrapper">
        <section className="section-frame overflow-hidden">
          <div className="wrapper bg-soft-primary">
            <div className="container py-12 py-md-16 text-center">
              <div className="row">
                <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                  <h1 className="display-1 mb-3">
                    The Geru Method Books
                  </h1>
                  <p className="lead px-lg-5 px-xxl-8 mb-1">
                    Here you can find the latest company books and training
                    programs.
                  </p>
                </div>
                {/* /column */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
          </div>
          {/* /.wrapper */}
        </section>
        {/* /section */}
        <section className="wrapper bg-light">
          <div className="container py-14 py-md-16">
            <div className="row gx-lg-8 gx-xl-12">
              <div className="col-lg-8">
                <div className="blog classic-view">
                  <article className="post">
                    <div className="card">
                      <div className="card-body">
                        <div className="post-header">
                          {/* /.post-category */}
                          <h2 className="post-title mt-1 mb-0">
                            The Geru Method - BOOKS{" "}
                          </h2>
                        </div>
                        {/* /.post-header */}
                        <div className="post-content">
                          <p>
                            Intelligence Surveillance Academy company offers a
                            series of covert surveillance books at various
                            levels of complexity. These books are accredited by
                            the ISO 9001 and ISO 27001 certifications. They provide valuable
                            knowledge and insights into the field of covert
                            surveillance.
                          </p>
                          <p>
                            The books cover a wide range of topics, combining
                            both theoretical and practical aspects to deliver
                            comprehensive learning experiences. The content is
                            progressively structured, allowing readers to grasp
                            the fundamentals before delving into more advanced
                            techniques.
                          </p>
                          <p>
                            Each book begins with theoretical foundations,
                            introducing readers to essential concepts and
                            principles. As readers progress, they encounter
                            practical case studies and real-life examples, which
                            enable them to apply the acquired knowledge
                            effectively.
                          </p>
                          <p>
                            Throughout these books, The Geru Method ensures that all necessary information
                            is provided, including details on logistics,
                            equipment, communication means, and hidden
                            documentation methods. Emphasis is placed on
                            understanding the importance of the human factor in
                            surveillance operations, as the surveillance officer
                            plays a crucial role in driving the success of any
                            mission.
                          </p>
                          <p>
                            <em>
                              While technology and equipment are acknowledged as
                              auxiliary tools, the company stresses the
                              significance of honing surveillance officers'
                              skills and decision-making abilities. The books
                              are updated regularly to keep up with the
                              ever-evolving landscape of covert surveillance.
                            </em>
                          </p>
                          <p>Our books are as follows:</p>
                        </div>
                        {/* /.post-content */}
                      </div>
                      {/*/.card-body */}
                    </div>
                    {/* /.card */}
                  </article>
                  {/* /.post */}
                </div>
                {/* /.blog */}
                <div className="blog grid grid-view">
                  <div className="row isotope gx-md-8 gy-8 mb-8">
                    {allBooksArticle}
                    {/* /.posts */}
                  </div>
                  {/* /.row */}
                </div>
                {/* /.blog */}
              </div>
              {/* /column */}
              <SideWidget />
              {/* /column .sidebar */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
        {/* /section */}
      </div>
    </>
  );
};
