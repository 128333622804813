import { Link, useParams } from "react-router-dom";
import { BasketContext, useBasket } from "../Context/BasketContext";
import { SideWidget } from "../SideWidget";

export const Book = ({ books }) => {
  const { addToBasketProduct } = useBasket();

  let { userId } = useParams();
  const bookIndex = userId - 1;
  const book = books[bookIndex];

  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        style={{ backgroundImage: 'url("../assets/img/photos/bg7.webp")' }}
      >
        <div className="container pt-18 pb-15 pt-md-20 pb-md-19 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link
                    to="/isa-books/all-books"
                    className="text-reset"
                    rel="category"
                  >
                    All Books
                  </Link>
                </div>
                {/* /.post-category */}
                <h1 className="display-1 mb-4 text-white">{book.title}</h1>
                <ul className="post-meta text-white">
                  <li className="post-author">
                    <i className="uil uil-user" />
                    <Link to="#" className="text-reset">
                      <span>By ISA</span>
                    </Link>
                  </li>
                </ul>
                {/* /.post-meta */}
              </div>
              {/* /.post-header */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img src={book.imgSrc} alt="" />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="flex items-center justify-between my-4 flex-col  sm:flex-row">
                          <p>
                            <span className="text-3xl font-bold text-slate-900">
                              {book.price} {book.currency}
                            </span>
                            {/*<span*/}
                            {/*    className="text-sm text-slate-900 line-through">$299</span>*/}
                          </p>
                          <button
                            onClick={() => addToBasketProduct(book.id, 1)}
                            className="flex items-center rounded-md bg-slate-900 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="mr-2 h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                              />
                            </svg>
                            Add to cart
                          </button>
                        </div>
                        <hr />
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4 mt-2">{book.title}</h2>
                          <p
                            style={{ whiteSpace: "pre-line" }}
                            dangerouslySetInnerHTML={{ __html: book.content }}
                          />
                        </div>
                        {/* /.post-content */}
                      </article>
                      {/* /.post */}
                    </div>
                    {/* /.classic-view */}
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.blog */}
            </div>
            {/* /column */}
            <SideWidget />
            {/* /column .sidebar */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </>
  );
};
