import { Link } from "react-router-dom";
import { SideWidget } from "../SideWidget";

export const Consultancy = () => {
  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        style={{
          backgroundImage: 'url("../assets/img/photos/consultancy.webp")',
        }}
      >
        <div className="container pt-18 pb-15 pt-md-20 pb-md-19 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link
                    to="/isa-services"
                    className="text-reset"
                    rel="category"
                  >
                    Services
                  </Link>
                </div>
                {/* /.post-category */}
                <h1 className="display-1 mb-4 text-white">
                  Service - Consultancy
                </h1>
                <ul className="post-meta text-white">
                  <li className="post-author">
                    <i className="uil uil-user" />
                    <Link to="#" className="text-reset">
                      <span>By ISA</span>
                    </Link>
                  </li>
                </ul>
                {/* /.post-meta */}
              </div>
              {/* /.post-header */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img src="../assets/img/photos/consultancy.webp" alt="" />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4">Service - Consultancy </h2>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            <strong>
                              THE CONSULTANT DOES NOT GIVE ADVICE TO CLIENTS, HE
                              PUTS EVERYTHING INTO PRACTICE AND MAKES IT
                              OPERATIONAL.
                            </strong>{" "}
                            Nowadays, the consultant understands that he is
                            somewhere very high up in surveillance and gives
                            advice, teaches lessons, delivers PowerPoint
                            presentations, etc. Totally wrong. The consultant is
                            the person who has so much knowledge that allows him
                            to do the tasks of anyone in a department: - driver,
                            foot operative, photographer, team leader. It's not
                            the consultant's degrees that make their value but
                            that experience and skill proven thousands of times
                            when everyone avoided making a decision.
                            <br />
                            <br />
                            <strong>
                              {" "}
                              1. COVERT-SURVEILLANCE CONSULTANCY:
                            </strong>{" "}
                            - Col. Geru Doru continues to teach the art of
                            surveillance to corporations, agencies, departments
                            and to get involved in setting them up from scratch
                            until they become operational followed by a
                            mentoring period.
                            <br /> <strong>
                              {" "}
                              2. MANAGEMENT CONSULTING
                            </strong>{" "}
                            for established departments whose activities suffer
                            from dysfunctions. It identifies vulnerabilities,
                            makes proposals for changes, makes changes, creates
                            the bond for teams to act naturally and carries out
                            mentoring.
                            <br />{" "}
                            <strong>
                              3. CONSULTING FOR CLOSE PROTECTION DEPARTMENTS
                            </strong>{" "}
                            - is involved in organising the team according to
                            his own method which drastically reduces risks and
                            vulnerabilities by combining several covert
                            surveillance methods and several specialisations.
                            <br />{" "}
                            <strong>
                              {" "}
                              4. OPERATIONAL MANAGEMENT CONSULTING
                            </strong>{" "}
                            - provides personal management alongside the
                            department manager for very complex cases, organized
                            crime, counter-terrorism, international pursuits or
                            for high-risk corporations, difficult areas or which
                            are intended to be very sensitive without leaving
                            any clues behind. He draws up the action strategy
                            and carries it out personally until results are
                            achieved.
                            <br />
                            <br />
                            <strong> CONSULTANCY - TRAIN THE TRAINER</strong> -
                            Performs training at a very advanced level in all
                            chapters, at the end focusing on high-level
                            managerial concretization. Following this course,
                            the trainers will accumulate the managerial
                            knowledge that I have accumulated in 32 years of
                            working at a very high level.
                            <br />
                            <br />
                            The book{" "}
                            <strong>
                              COVERT SURVEILLANCE BEST PRACTICE - THE GERU
                              METHODS - VOLUME 1
                            </strong>{" "}
                            analyses the covert surveillance activity on 3
                            levels:
                            <br />{" "}
                            <strong>
                              {" "}
                              1. Theory
                              <br /> 2. Application of theory at high level with
                              minimum 5 cars and 1 VAN
                              <br /> 3. Substantiation of theory
                            </strong>
                            , its application and the author's experience in
                            cases of real actions explained with working
                            variants, risk variants, risk avoidance methods,
                            unforeseen events in the field and their remedy. No
                            word is put outside the specialization of covert
                            surveillance, no sentence refers to anything else.
                            Everything is compressed to provide a huge amount of
                            knowledge without reading things that do not concern
                            this specialization.
                            <br />
                            <br />
                            The book{" "}
                            <strong>
                              COVERT SURVEILLANCE BEST PRACTICE - THE GERU
                              METHODS - VOLUME 2
                            </strong>{" "}
                            is the special part of covert surveillance that,
                            after assimilating what has been mentioned, enables
                            us to move on to understanding high-risk actions,
                            where working environments change, dealing with
                            surveillance in the rural environment which still
                            eludes the majority of the professionals, explaining
                            how to carry out surveillance on all the continents,
                            the specifics of each and the methods indicated for
                            each specific environment. We also tackle almost
                            impossible actions in seaside resorts, in the
                            mountains where the target is very difficult to
                            monitor.
                            <br />
                            <br />
                            <strong> PROMOTIONS OFFERED:</strong>
                            <br /> 1. For those who purchase both volumes-130
                            GBP
                            <br /> 2. For those who purchase the online course
                            and both Volumes- 130 GBP
                            <br /> 3. For those attending the training- 130 GBP
                          </p>
                          <br />
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SideWidget />
          </div>
        </div>
      </section>
    </>
  );
};
