import React, {  useEffect, useState } from "react";
import Axios from "axios";
import Cookies from "js-cookie";

import {useUser} from "../Context/UserContext";
import {toast} from "react-toastify";

export const MyCourses = ({onlineCourses }) => {
    const { loggedIn } = useUser();
    const [myCoursesData,setMyCoursesData]=useState([])

    useEffect(() => {
        if(loggedIn){
            Axios.post("/api/coursesByUser", {
                token:Cookies.get('token'),
            })
                .then((response) => {
                    if(!!response.data.courses.length){
                        setMyCoursesData(response.data.courses.map((item)=>
                                onlineCourses.find((course)=>course.id===item.course_id)
                        ))
                    }
                })
                .catch((error) => {
                    toast("Oops! Something went wrong.", { type: "error" });
                });
        }
    }, [loggedIn]);
    React.useEffect(() => {
        if (!Cookies.get('token')) {
            window.location.href = `/login`;
        }
    }, []);

    const Courses= () => {
        return (
            myCoursesData &&
            myCoursesData?.map((product, index) => (
                <div className="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start">
                    <img
                        style={{ objectFit: "contain", borderRadius: "10px" }}
                        src={product.imgSrc}
                        alt={product.title}
                        className="w-full rounded-lg sm:w-40"
                    />
                    <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between gap-3">
                        <div className="mt-5 sm:mt-0">
                            <a className="text-lg font-bold text-gray-900 hover:underline" href={product.link}>{product.title}</a>
                            <p className="mt-1 text-xs text-gray-700"
                               style={{ whiteSpace: "pre-line" }}
                               dangerouslySetInnerHTML={{ __html: `${product.content.slice(0, 100)}...`}}
                            />
                        </div>
                    </div>
                </div>
            ))
        );
    };
    return (
        <div className="h-full min-h-[550px] bg-gray-100 py-10">
            <h1 className="mb-10 text-center text-2xl font-bold">My Online Courses</h1>
            <div className="mx-auto flex flex-col gap-6 justify-center px-6 max-w-5xl md:space-x-6 lg:flex-row xl:px-0">
                <div className="rounded-lg m-0 w-full lg:w-2/3  ">

                    {myCoursesData.length>0 ?
                        <Courses />
                        :
                        <h2 style={{
                        fontSize: "30px",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        opacity: "70%",
                            textAlign:"center"
                    }}>You don't have any online courses purchased</h2>}
                </div>
            </div>
        </div>
    );
};
