import { Link } from "react-router-dom";
import { SideWidget } from "../SideWidget";

export const InvestigationsCourse1 = () => {
  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        style={{ backgroundImage: 'url("../assets/img/photos/bg7.webp")' }}
      >
        <div className="container pt-18 pb-15 pt-md-20 pb-md-19 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link
                    to="/isa-investigations-courses"
                    className="text-reset"
                    rel="category"
                  >
                    Investigations Courses
                  </Link>
                </div>
                <h1 className="display-1 mb-4 text-white">
                  DEEPENING COURSE IN COVERT SURVEILLANCE MANAGEMENT - FOR MANAGERS, DIRECTORS, TEAM LEADERS
                </h1>
                <ul className="post-meta text-white">
                  <li className="post-author">
                    <i className="uil uil-user" />
                    <Link to="#" className="text-reset">
                      <span>By ISA</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img src="../assets/img/photos/b1.jpg" alt="" />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4">
                            AWARD IN COVERT SURVEILLANCE MANAGEMENT FOR
                            MANAGERS, DIRECTORS, TEAM LEADERS
                          </h2>
                          <p style={{ whiteSpace: "pre-line" }}>
                            THE DEFINITION OF COVERT SURVEILLANCE <br />
                            MANAGEMENT OF THE PREPARATION OF THE COVERT
                            SURVEILLANCE ACTION
                            <br />
                            DISGUISE AND MAKE-UP MANAGEMENT
                            <br />
                            MANAGEMENT OF THE RISK ASSESSMENT IN THE CASE OF
                            SURVEILLANCE
                            <br />
                            SURVEILLANCE-SPECIFIC DISGUISE AND MAKE-UP
                            <br />
                            MANAGEMENT OF THE COMMUNICATION METHODS USED IN
                            SURVEILLANCE
                            <br />
                            MANAGEMENT OF THE:
                            <br />
                          </p>
                          <ul>
                            <li>• using radio stations in surveillance</li>
                            <li>• using radio stations in surveillance</li>
                            <li> • delta crop top; (female)</li>
                            <li> • delta-5 long body, no zip</li>
                            <li>
                              {" "}
                              • delta-5 long body, with zip combined
                              microphone/inductor
                            </li>
                            <li> • neckloop inductor/microphone</li>
                            <li> • wireless combined microphone/inductor</li>
                            <li> • digital wireless earpiece</li>
                            <li> • micro wireless earpiece</li>
                            <li> • micro digital wireless earpiece</li>
                            <li> • stealthx wireless earpiece kit</li>
                            <li> • digitalx wireless earpiece kit</li>
                          </ul>
                          <p style={{ whiteSpace: "pre-line" }}>
                            MANAGEMENT OF ORGANIZING A SURVEILLANCE OPERATION
                            <br />
                            MANAGEMENT OF THE MANDATORY DEBRIEFINGS - OCCASIONAL
                            MEETINGS IMPOSED BY THE OPERATIVE STATUS
                            <br />
                            MANAGEMENT OF THE SETTING UP AND ORGANIZING POSTS
                            AND WAITING POINTS
                            <br />
                            MANAGEMENT OF THE PHOTOGRAPHING AND VIDEO RECORDING
                            <br />
                            MANAGEMENT OF THE PERFORMING SURVEILLANCE ACTIONS
                            <br />
                            MANAGEMENT OF THE PREPARING THE ACTION. TAKING THE
                            INVESTIGATED PERSON UNDER
                            <br />
                            SURVEILLANCE. THE FIRST SURVEILLANCE DAY
                            <br />
                            MANAGEMENT OF FOOT SURVEILLANCE
                            <br />
                          </p>
                          <ul>
                            <li>
                              • surveillance performed by 1 professional
                              investigator
                            </li>
                            <li>
                              • surveillance performed by 2 professional
                              investigators
                            </li>
                            <li>
                              • surveillance performed by a team of
                              investigators
                            </li>
                            <li>
                              • how a team acts when the target is moving
                              through busy streets
                            </li>
                            <li>
                              • how a team acts when the target is moving along
                              wide boulevards
                            </li>
                            <li>
                              • with at least 2 lanes in each direction and very
                              busy pavements
                            </li>
                            <li>
                              • foot surveillance when the target moves through
                            </li>
                            <li>• neighbourhoods</li>
                            <li>
                              • foot surveillance when the target uses the bus
                              or trolleybus as
                            </li>
                            <li>• public transport means</li>
                            <li>
                              • foot surveillance when the target uses the
                              subway as public
                            </li>
                            <li>• transport means.</li>
                            <li>
                              • foot surveillance when the target uses public
                              transport means
                            </li>
                            <li>• between localities (bus, train, ship)</li>
                            <li>• operative surveillance in trains</li>
                            <li> • placing contacts</li>
                            <li>• car surveillance</li>
                            <li>
                              • back-up options if car investigators cannot take
                              over the target.
                            </li>
                          </ul>
                          <p style={{ whiteSpace: "pre-line" }}>
                            MANAGEMENT OF THE PERFORMING CAR SURVEILLANCE. THE
                            POSITIONED
                          </p>

                          <ul>
                            <li>
                              {" "}
                              • posts and change of plans in the case of mobile
                              surveillance
                            </li>
                            <li>
                              {" "}
                              • natural ways of changing planes that do not
                              attract target attention
                            </li>
                            <li> • how to act outside the localities</li>
                            <li> • car surveillance at night</li>
                            <li>
                              {" "}
                              • communication procedures during car surveillance
                            </li>
                            <li>
                              {" "}
                              • action coordination management in case of car
                              surveillance.
                            </li>
                            <li> • rural surveillance</li>
                            <li>
                              {" "}
                              • general considerations on rural surveillance
                            </li>
                            <li> • conducting rural surveillance</li>
                            <li>
                              {" "}
                              • acquiring the target in rural environment
                            </li>
                            <li>
                              {" "}
                              • how to document the target in the rural
                              environment.
                            </li>
                            <li>
                              {" "}
                              • how to harmonise the clothing of the
                              surveillance operative
                            </li>
                            <li>
                              {" "}
                              • during the surveillance in a rural environment.
                            </li>
                            <li>
                              {" "}
                              • rural surveillance coordination management.
                              daily logistical
                            </li>
                            <li>
                              {" "}
                              • measures and rethinking of human resources
                              according to the
                            </li>
                            <li> • environment and the course of action</li>
                            <li>
                              {" "}
                              • pros and cons of using military research methods
                              in rural surveillance
                            </li>
                          </ul>
                          <p style={{ whiteSpace: "pre-line" }}>
                            {" "}
                            MANAGEMENT IN SURVEILLANCE CARRIED OUT IN LEISURE
                            RESORTS{" "}
                          </p>
                          <ul>
                            <li>
                              • surveillance in mountain spas and leisure
                              resorts.
                            </li>
                            <li>
                              {" "}
                              • surveillance in seaside spas and leisure
                              resorts.
                            </li>
                          </ul>

                          <p style={{ whiteSpace: "pre-line" }}>
                            MANAGEMENT OF ACTION IN CASE - LOSS OF TARGET. ROOT
                            CAUSE ANALYSIS. IMMEDIATE MEASURES TAKEN IN THE
                            FIELD TO RESUME SURVEILLANCE
                          </p>
                          <ul>
                            <li>• LOSS OF TARGET ON ACQUISITION</li>
                            <li> • LOSS OF TARGET DURING FOOT SURVEILLANCE</li>
                            <li>
                              {" "}
                              • LOSS OF THE TARGET DURING CAR SURVEILLANCE
                            </li>
                          </ul>

                          <p style={{ whiteSpace: "pre-line" }}>
                            IMMEDIATE MEASURES, RESUMPTION, SUSPENSION OR
                            CESSATION OF
                            <br />
                            SURVEILLANCE ON A CASE-BY-CASE BASIS
                            <br />
                            MANAGEMENT IN CASE OF LOSS OF THE TARGET DURING CAR
                            SURVEILLANCE
                            <br />
                            MANAGEMENT OF DETECTION OF SURVEILLANCE
                            <br />
                          </p>
                          <ul>
                            <li>
                              {" "}
                              • detecting surveillance when the target is
                              walking
                            </li>
                            <li>
                              {" "}
                              • detecting surveillance while travelling by
                              public transportation means
                            </li>
                            <li> • detecting car surveillance</li>
                            <li>
                              {" "}
                              • establishing a travel route for detecting car
                              surveillance
                            </li>
                          </ul>

                          <p style={{ whiteSpace: "pre-line" }}>
                            MANAGEMENT OF COUNTERSURVEILLANCE{" "}
                          </p>
                          <ul>
                            <li>
                              {" "}
                              • countersurveillance through the route method
                            </li>
                            <li>
                              {" "}
                              • countersurveillance without the route method
                            </li>{" "}
                          </ul>
                          <p style={{ whiteSpace: "pre-line" }}>
                            MANAGEMENT FOR COMPENSATING FOR THE LOW NUMBER OF
                            OPERATIVES IN SURVEILLANCE ACTIVITIES
                          </p>
                          <ul>
                            <li>
                              {" "}
                              • cases requiring the use of special measures to
                              compensate the number of private investigators
                            </li>
                            <li>
                              {" "}
                              • compensating for the low number of professional
                              investigators in the field{" "}
                            </li>
                          </ul>
                          <p style={{ whiteSpace: "pre-line" }}>
                            MANAGEMENT IN CASE TO FLAGRANTE DELICTO DURING THE
                            SURVEILLANCE ACTION, THE MANAGEMENT OF DRAFTING THE
                            ACTION REPORT{" "}
                          </p>
                          <ul>
                            <li>
                              {" "}
                              • the change in field dynamics and measures taken
                              accordingly
                            </li>
                            <li> • judicial surveillance</li>
                            <li>
                              {" "}
                              • surveillance of corporate investigation
                              departments
                            </li>
                            <li>
                              {" "}
                              • surveillance carried out by private detectives
                            </li>{" "}
                          </ul>
                          <p style={{ whiteSpace: "pre-line" }}>
                            MANAGEMENT ADAPTED TO THE 7 CONTINENTS
                          </p>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SideWidget />
          </div>
        </div>
      </section>
    </>
  );
};
