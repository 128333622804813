import React from "react";
import Axios from "axios";
import Cookies from "js-cookie";
import {
  emailValidation,
  nameValidation,
  passwordConfirmationValidation,
  passwordValidation,
} from "../../utils/inputValidations";
import { useParams } from "react-router-dom";
import {StyledError} from "./Login";
import {toast} from "react-toastify";

export const PasswordRecovery = () => {
  const { token } = useParams();

  const [credentials, setCredentials] = React.useState({
    newPassword: "",
    confirmNewPassword:""
  });
  React.useEffect(() => {
    if (Cookies.get('token')) {
      window.location.href = `/`;
    }
  }, []);


  const [errorState, setErrorState] = React.useState(false);
  const handleRegister = () => {
    if (errorState) {
      return;
    }
    Axios.post(`/api/reset-password/${token}`, {
      newPassword: credentials.newPassword,
    })
      .then((response) => {
        window.location.href = `/login`;
      })
      .catch((error) => {
        toast("Oops! Something went wrong.", { type: "error" });
      });
  };
  const [passwordError, setPasswordError] = React.useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] =
    React.useState("");
  const onChangeHandle = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.target.id === "newPassword") {
      setPasswordError(passwordValidation(e.target.value));
      setPasswordConfirmationError(
          passwordConfirmationValidation(e.target.value, credentials.confirmNewPassword),
      );
      credentials.newPassword = e.target.value;
    }
    if (e.target.id === "password-confirmation") {
      setPasswordConfirmationError(
          passwordConfirmationValidation(e.target.value, credentials.newPassword),
      );
      credentials.confirmNewPassword = e.target.value;
    }
  };

  React.useEffect(() => {
    if (
        passwordError === "" && passwordConfirmationError===""
    ) {
      if (credentials.newPassword !== "" && credentials.confirmNewPassword !== "") {
        setErrorState(false);
      } else {
        setErrorState(true);
      }
    } else {
      setErrorState(true);
    }
  }, [passwordError,passwordConfirmationError]);
  return (
    <section className="my-8 my-lg-16">
      <div className="container h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-lg-12 col-xl-11">
            <div
              className="card text-black z-0"
              style={{ borderRadius: 25, backgroundColor: "#f1f5fd" }}
            >
              <div className="card-body p-md-5">
                <div className="row justify-content-center">
                  <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1 d-flex flex-column justify-content-center">
                    <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                      Change your password
                    </p>
                    <div className="mx-1 mx-md-4">
                      <div className="d-flex flex-row align-items-center mb-4 position-relative">
                        <div className="form-outline flex-fill mb-0">
                          <label
                            className="form-label text-primary"
                            htmlFor="form3Example1c"
                          >
                            Your New Password
                          </label>
                          <input
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            className="form-control text-primary z-1"
                            onChange={onChangeHandle}
                          />
                          {passwordError && <StyledError>{passwordError}</StyledError>}
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center mb-4 position-relative">
                        <div className="form-outline flex-fill mb-0">
                          <label
                            className="form-label text-primary"
                            htmlFor="form3Example1c"
                          >
                            Confirm Your New Password
                          </label>
                          <input
                            type="password"
                            id="password-confirmation"
                            name="newPassword"
                            className="form-control text-primary z-1"
                            onChange={onChangeHandle}
                          />
                          {passwordConfirmationError && <StyledError>{passwordConfirmationError}</StyledError>}
                        </div>
                      </div>
                      <div className="text-center text-lg-start mt-4 pt-2 d-flex flex-row align-items-center justify-content-center">
                        <button
                          type="button"
                          disabled={errorState}
                          className="btn btn-primary btn-lg self-center"
                          style={{
                            paddingLeft: "2.5rem",
                            paddingRight: "2.5rem",
                          }}
                          onClick={handleRegister}
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2 justify-content-center w-[200px] lg:w-[400px]">
                    <img
                      src="../../assets/img/photos/lock.png"
                      className="img-fluid"
                      alt="Sample image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
