import { Link } from "react-router-dom";
import { SideWidget } from "./SideWidget";

export const AboutUs = () => {
  return (
    <>
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img src="../assets/img/photos/b1.jpg" alt="" />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4">
                            WE REPRESENT THE BEST CHOICE FOR YOU TO BECOME TRUE
                            PROFESSIONALS
                          </h2>
                          <p>
                            The graduation of our courses is the guarantee of
                            obtaining hard to get performance. Our trainers are
                            former covert survellance officers.
                          </p>
                          <p>
                            Our strong point is the emphasis on how the human
                            factor works in covert surveillance operations. The
                            technical means are constantly changing and
                            modernizing. 90% of the success of a covert
                            surveillance action is the preparation of the human
                            factor involved in the operation. This is where we
                            make a difference!
                          </p>
                          <p>
                            We do not train specialists in photographic
                            techniques, but specialists in covert surveillance
                            with skills to use any technical means.
                          </p>
                          <p>
                            We teach specialists how to handle the simple,
                            common and complex actions of covert surveillance,
                            how to carry out all the methods of covert
                            surveillance at a high level, how to adapt the
                            methods of covert surveillance and intelligence for
                            solving professional investigations.
                          </p>
                          <p>
                            If you want to acquire this knowledge from a
                            theoretical and practical point of view, then you
                            have turned to the perfect company suitable for your
                            needs.
                          </p>
                          <h3 className="h2 mb-4">
                            The company is led by: Colonel.r. Geru Doru
                          </h3>
                          <ul>
                            <li>
                              former chief of police - covert surveillance&amp;
                              intelligence, trainer in the special services of
                              the police
                            </li>
                            <li>
                              specialist in undercover criminal investigations
                            </li>
                            <li>specialist in forensic investigations</li>
                            <li>
                              owner of an investigation agency since 2012,
                              private detective
                            </li>
                            <li>
                              criminal investigation lawyer, professor of covert
                              surveillance at Danubius University based on the
                              cooperation between the Geru Intelligence Masters
                              company and the faculty
                            </li>
                            <li>
                              20 years in the special services of the police,
                              covert surveillance, intelligence and undercover
                              investigațions for organized crime offenses
                            </li>
                            <li>
                              10 years in covert surveillance and undercover
                              investigations for private clients and
                              corporations from almost the countries of the
                              European Union: United Kingdom, Spain, Italy,
                              Germany, Israel, France, Fellow Member of the
                              organization World Association at Professional
                              Investigators
                            </li>
                          </ul>
                          <h3 className="h2 mb-4">
                            Work experience of Colonel.r. Geru Doru:
                          </h3>
                          <h4>February 2012 – present: CRIMINAL LAWYER</h4>
                          <p>
                            Providing advice to clients related to law,
                            representing persons or companies in disputes,
                            representing any type of company with a variety of
                            cases, attending court hearing.
                          </p>
                          <h4>
                            Aug 2021-present: OWNER – FOUNDER - GERU
                            INTELLIGENCE MASTERS SRL
                          </h4>
                          <p>
                            Courses of Surveillance courses for police,
                            corporate, security forces. The courses are for
                            beginners, advanced and personal security activities
                            using surveillance methods at Danubius University.
                          </p>
                          <h4>
                            Aug 2021-present: OWNER – FOUNDER - PRIVATE
                            INVESTIGATIONS AGENCY – REFLECTOR SRL{" "}
                          </h4>
                          <p>
                            Operating within security consultancy, offering risk
                            assessments and solutions to private companies
                            regarding security breaches, covert surveillance and
                            undercover investigations, managing day to day
                            operational aspects of covert surveillance and
                            undercover investigations including constructing,
                            developing, training and mentoring investigating
                            teams, coordinating all investigative processes,
                            collating evidence leading to prosecution;
                            establishing intelligence unit to improve
                            intelligence gathering processes and procedures.
                          </p>
                          <h4>
                            Jul 2010 – Feb 2012: DEPUTY CHIEF INSPECTOR (60
                            sub-units, 1300 police officers), Ministry of
                            Internal Affairs
                          </h4>
                          <p>
                            Responsible for monitoring and evaluating the
                            effectiveness and the responsiveness of the
                            following departments: forensic investigation,
                            security, crime prevention, intelligence analysis,
                            guns and explosives/macro-economic/ traffic
                            investigations, criminal records.
                          </p>
                          <p>
                            Responsible for conducting internal disciplinary and
                            gross misconduct investigations Assessed training
                            needs and requirements to devise and deliver
                            appropriate programmes.
                          </p>
                          <h4>
                            Sep 2003 – Jul 2010: CHEAF OF POLICE - COVERT
                            SURVEILLANCE &amp; UNDERCOVER INVESTIGATIONS FOR 3
                            COUNTIES (General Department of Organised Crime,
                            Ministry of Internal Affairs)
                          </h4>
                          <ul>
                            <li>
                              Responsible for organising surveillance using
                              technical synergy for covert operations in
                              cooperation National Prosecutor (Director for
                              Investigation of Crossborder Organised Crime) and
                              DNA (National Anticorruption Department )
                            </li>
                            <li>
                              Criminal activities included drug trafficking,
                              people cigarettes smuggling into the EU, major gun
                              crime, fraud, cyber-crimes, ATM frauds, money
                              laundering, international wanted criminals
                            </li>
                            <li>
                              Responsible for the supervision and reporting on
                              development of surveillance operations
                            </li>
                            <li>
                              Established 2 undercover surveillance units{" "}
                            </li>
                            <li>
                              Managed 1500 covert surveillance operations{" "}
                            </li>
                            <li>
                              Strategically reviewed security policy, strategy
                              &amp; procedures to drive continuous improvement
                            </li>
                            <li>
                              Accountable for security of all staff, identifying
                              and eliminating potential risks
                            </li>
                            <li>
                              Produced comprehensive reports for presentation to
                              management on case progress
                            </li>
                            <li>
                              Developed and delivered training and coaching on
                              technical and operational activities
                            </li>
                            <li>
                              Managed assessment and recognition of risk in
                              order to develop appropriate strategies
                            </li>
                            <li>
                              Analysed daily surveillance log and decided on
                              further action to improve effectiveness
                            </li>
                            <li>
                              Provide statements of evidence in support of the
                              criminal prosecution of targets
                            </li>
                          </ul>
                          <h4>
                            May 2003 - September 2003: CHIEF OF CRIMINAL
                            INVESTIGATIONS (60 sub-units, 1300 police officers),
                            Ministry of Internal Affairs Coordinating security
                            and criminal investigations activities in county
                            (450.000 citizens)
                          </h4>
                          <p>
                            Directing, managing, and overseeing the activities
                            and operations of the Criminal Investigations.
                            Select, train, motivate, and evaluate assigned
                            personnel; provide or coordinate staff training;
                            work with employees to correct deficiencies.
                          </p>
                          <h4>
                            September 1998 – December 2001: Police officer –
                            SENIOR CRIMINAL INVESTIGATOR Ministry of Internal
                            Affairs
                          </h4>
                          <ul>
                            <li>
                              Coordinating criminal investigation activities in
                              2 cities and 22 villages in the region (20.000
                              citizens) Coordinating the activity of 100
                              policemen within an area of 20 villages and 2
                              cities
                            </li>
                            <li>
                              Coordinating the investigations happening in the
                              area Authorizing the criminal prosecution
                              Approving all the files which were sent by the
                              police to the prosecutor
                            </li>
                          </ul>
                          <h4>
                            October 1992 – September 1998: Police Officer
                            (agent) – Criminal Investigator Ministry of Internal
                            Affairs
                          </h4>
                          <p>
                            I was part of teams which investigated crimes,
                            assessing the crime scenes and I helped the
                            experienced investigation officers who were
                            gathering information and evidence and preparing the
                            files under the prosecutor's coordination.
                          </p>
                        </div>
                        {/* /.post-content */}
                      </article>
                      {/* /.post */}
                    </div>
                    {/* /.classic-view */}
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.blog */}
            </div>
            {/* /column */}
            <SideWidget />
            {/* /column .sidebar */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </>
  );
};
