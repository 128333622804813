import {Link, useLocation} from "react-router-dom";
import React, { useEffect } from "react";

import { HeaderHomepage } from "./HeadersComponents/HeaderHomepage";
import { HeaderAboutUs } from "./HeadersComponents/HeaderAboutUs";
import { HeaderGeneral } from "./HeadersComponents/HeaderGeneral";
import { FooterHomepage } from "./FootersComponents/FooterHomepage";
import { FooterGeneral } from "./FootersComponents/FooterGeneral";
import { HeaderContact } from "./HeadersComponents/HeaderContact";

export const BodyWrapper = ({ children }) => {
  useEffect(() => {
    const progressWrap = document.querySelector(".progress-wrap");
    const progressPath = document.querySelector(".progress-wrap path");
    const pathLength = progressPath.getTotalLength();
    const offset = 50;

    if (progressWrap !== null) {
      progressPath.style.transition = progressPath.style.WebkitTransition =
        "none";
      progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
      progressPath.style.strokeDashoffset = pathLength;
      progressPath.getBoundingClientRect();
      progressPath.style.transition = progressPath.style.WebkitTransition =
        "stroke-dashoffset 10ms linear";

      const handleScroll = () => {
        const scroll =
          document.body.scrollTop || document.documentElement.scrollTop;
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight;
        const progress = pathLength - (scroll * pathLength) / height;
        progressPath.style.strokeDashoffset = progress;
        const scrollElementPos =
          document.body.scrollTop || document.documentElement.scrollTop;
        if (scrollElementPos >= offset) {
          progressWrap.classList.add("active-progress");
        } else {
          progressWrap.classList.remove("active-progress");
        }
      };

      window.addEventListener("scroll", handleScroll);

      const handleClick = (e) => {
        e.preventDefault();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      };

      progressWrap.addEventListener("click", handleClick);

      return () => {
        window.removeEventListener("scroll", handleScroll);
        progressWrap.removeEventListener("click", handleClick);
      };
    }
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);


  const locationUrl = window.location.pathname;
  const renderHeader = (locationUrl) => {
    switch (locationUrl) {
      case "/" || "Home":
        return <HeaderHomepage />;
      case "/isa-about-us":
        return <HeaderAboutUs />;
      case "/contact":
        return <HeaderContact />;
      default:
        return <HeaderGeneral />;
    }
  };

  return (
    <>
      <div className="content-wrapper">
        {renderHeader(locationUrl)}
        {children}
        {locationUrl === ("/" || "Home") ? (
          <FooterHomepage />
        ) : (
          <FooterGeneral />
        )}
        <div className="progress-wrap">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
      </div>
    </>
  );
};
