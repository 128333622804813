import React from 'react';
function VideoPlayer({videoSrc}) {
    return (
        <div className="w-100">
            <video className="w-100" controls width="640" controlsList="nodownload" height="360">
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

export default VideoPlayer;