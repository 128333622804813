import {Link} from "react-router-dom";

export interface coursesProps{
    courses:{name:string,link:string,img:string}[]
}

interface courseCardProps{
    name:string
    link:string
    img:string
}
export const CourseCard=(props:courseCardProps)=>{
    return<article className="item post col-md-6">
        <div className="card">
            <figure className="card-img-top overlay overlay-1 hover-scale">
                <Link to={props.link}>
                    <img src={props.img} alt={props.name.slice(0,10)} />
                </Link>
                <figcaption>
                    <h5 className="from-top mb-0">Read More</h5>
                </figcaption>
            </figure>
            <div className="card-body">
                <div className="post-header">
                    <h2 className="post-title h3 mt-1 mb-3">
                        <Link
                            className="link-dark"
                            to={props.link}
                        >
                            {props.name}
                        </Link>
                    </h2>
                </div>
            </div>
        </div>
    </article>

}