import { Link } from "react-router-dom";
import { SideWidget } from "../SideWidget";

export const CookiePolicy = () => {
  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        style={{ backgroundImage: 'url("../assets/img/photos/lglbg.webp")' }}
      >
        <div className="container pt-18 pb-15 pt-md-20 pb-md-19 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link
                    to="/terms-and-conditions"
                    className="text-reset"
                    rel="category"
                  >
                    Legal
                  </Link>
                </div>
                <h1 className="display-1 mb-4 text-white">Cookie Policy</h1>
                <ul className="post-meta text-white">
                  <li className="post-author">
                    <i className="uil uil-user" />
                    <Link to="#" className="text-reset">
                      <span>By ISA</span>
                    </Link>
                  </li>
                </ul>
                {/* /.post-meta */}
              </div>
              {/* /.post-header */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img src="../assets/img/photos/lglbg.webp" alt="" />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4">Cookie Policy </h2>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            At Intelligence Surveillance Academy, we use cookies and
                            similar technologies to enhance your experience on
                            our website and to collect information about your
                            browsing habits. This Cookie Policy explains what
                            cookies are, how we use them, and your choices
                            regarding their use. By using our website, you
                            consent to our use of cookies as described in this
                            policy.
                          </p>
                          <br />
                          <strong> What Are Cookies?</strong>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            Cookies are small text files that are placed on your
                            device (computer, tablet, smartphone) when you visit
                            a website. They serve various purposes, including
                            recognizing you as a user, remembering your
                            preferences, and analyzing how you interact with the
                            website.
                          </p>
                          <br />
                          <strong> Types of Cookies We May Use</strong>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            <ol>
                              <li>
                                {" "}
                                1. Essential Cookies: These cookies are
                                necessary for the website to function properly.
                                They enable basic functions like page navigation
                                and access to secure areas of the website. You
                                cannot opt-out of these cookies.
                              </li>
                              <li>
                                {" "}
                                2. Functional Cookies: These cookies allow the
                                website to remember choices you make (such as
                                your language or region) and provide enhanced,
                                more personalized features.
                              </li>
                              <li>
                                {" "}
                                3. Analytics Cookies: We use analytics cookies
                                to collect information about how visitors use
                                our website, which pages they visit, and how
                                long they stay on each page. This data helps us
                                improve our website's performance and content.
                              </li>
                              <li>
                                {" "}
                                4. Marketing Cookies: Marketing cookies are used
                                to track visitors across websites. The intention
                                is to display ads that are relevant and engaging
                                for the individual user.
                              </li>
                            </ol>
                          </p>
                          <br />
                          <strong> Third-Party Cookies</strong> <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            We may also use cookies from third-party service
                            providers, such as analytics and marketing partners.
                            These cookies are subject to the respective privacy
                            policies of these third parties.
                          </p>{" "}
                          <br />
                          <strong> Your Cookie Choices</strong> <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            You can manage your cookie preferences through your
                            web browser settings. Most browsers allow you to
                            block or delete cookies. Please note that blocking
                            or deleting certain cookies may impact your ability
                            to use our website fully. For more information on
                            how to manage cookies in your browser, please refer
                            to the help section of your browser or visit
                            websites like AboutCookies.org.
                          </p>{" "}
                          <br />
                          <strong> Changes to This Cookie Policy</strong> <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            We may update this Cookie Policy from time to time
                            to reflect changes in our use of cookies or changes
                            in applicable regulations. We encourage you to
                            review this policy periodically for updates.
                          </p>
                          <br />
                          <strong> Contact Us</strong>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            If you have any questions or concerns about our
                            Cookie Policy or your cookie preferences, please
                            contact us at: Intelligence Surveillance Academy 88
                            Church Road, Stockton-On-Tees, TS18 1TW ,
                            +40757047733 , office@isasurveillancetraining.co.uk
                          </p>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SideWidget />
          </div>
        </div>
      </section>
    </>
  );
};
