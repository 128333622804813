import { Link } from "react-router-dom";

export const Post = ({ link, imgSrc, title, content }) => {
  return (
    <article className="item post col-md-6">
      <div className="card">
        <figure className="card-img-top overlay overlay-1 hover-scale">
          <Link to={link} className="">
            <img src={imgSrc} alt={imgSrc ? title : ""} />
          </Link>
          <figcaption>
            <h5 className="from-top mb-0 bg-black bg-opacity-50">Read More</h5>
          </figcaption>
        </figure>
        <div className="card-body">
          <div className="post-header">
            <h2 className="post-title h3 mt-1 mb-3">
              <Link className="link-dark" to={link}>
                {title}
              </Link>
            </h2>
          </div>
          <div className="post-content">
            <p dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </div>
    </article>
  );
};
